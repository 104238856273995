import { Component, forwardRef, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
} from "@danielmoncada/angular-datetime-picker";
import { DateTime, Zone } from "luxon";
import {
  CUSTOM_DATE_TIME_FORMATS,
  LuxonDateTimeAdapter,
} from "src/app/core/forms/luxon-date-time-adapter";
import { TimeOfDay } from "../time-of-day.model";
import { BaseFieldComponent } from "./base-field.component";

@Component({
  selector: "mr-date-time-picker-base[fieldId][descriptionId][label][timeZone]",
  styleUrls: [
    "./textbox.component.scss",
    "./date-time-picker-base.component.scss",
  ],
  template: `
    <div class="field">
      <mr-icon name="calendar"></mr-icon>
      <mr-icon
        name="close"
        *ngIf="value && !isDisabled && !disableClear"
        (click)="onChange(null)"
      ></mr-icon>
      <input
        [id]="fieldId"
        [attr.aria-label]="label || null"
        [attr.aria-describedby]="descriptionId"
        selectMode="single"
        [ngModel]="value"
        [owlDateTime]="picker"
        [owlDateTimeTrigger]="picker"
        [disabled]="isDisabled"
        [max]="maxDateTime"
        [min]="minDateTime"
        readonly
        (ngModelChange)="onChange($event)"
      />
      <owl-date-time
        #picker
        [pickerType]="fixedTime ? 'calendar' : 'both'"
        [startAt]="value || startTime"
        [stepMinute]="minuteStep"
        [stepHour]="1"
        [hour12Timer]="true"
        [disabled]="isDisabled"
        backdropClass="mr-date-time-picker-backdrop"
        panelClass="mr-date-time-picker-panel"
        (afterPickerClosed)="onBlur()"
      ></owl-date-time>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimePickerBaseComponent),
    },
    { provide: DateTimeAdapter, useClass: LuxonDateTimeAdapter },
    { provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS },
  ],
})
export class DateTimePickerBaseComponent extends BaseFieldComponent<DateTime | null> {
  public declare fieldId: string;

  @Input() public descriptionId!: string;
  @Input() public minuteStep = 1;
  @Input() public minDateTime: DateTime | null = null;
  @Input() public maxDateTime: DateTime | null = null;
  @Input() public disableClear = false;

  /** The fixed, unchangeable time to set in the result. */
  @Input() public fixedTime?: TimeOfDay;

  @Input() public set timeZone(value: Zone) {
    this._timeZone = value;
    this.startTime = DateTime.utc().setZone(value).startOf("day");
  }
  public get timeZone(): Zone {
    return this._timeZone;
  }
  private _timeZone!: Zone;

  // Set an explicit start time so the library doesn't auto-fill with the
  // current time. Setting the current time messes up the step logic, so that if
  // the step is 15 and the current time is 8:24, the next step would be 8:39
  // instead of 8:30 (though 8:24 should never have been allowed in the first
  // place).
  public startTime!: DateTime;

  public override onChange(value: DateTime | null): void {
    value = this.constrainToMinimum(value);
    super.onChange(this.adjustTime(value));
  }

  public override writeValue(value: DateTime | null): void {
    super.writeValue(this.adjustTime(value));
  }

  private adjustTime(value: DateTime | null): DateTime | null {
    if (!value) {
      return null;
    }

    const valueWithCorrectTime = this.fixedTime
      ? value.set(this.fixedTime)
      : value;

    // Take whatever time is displayed to the user and force it into the
    // correct time zone, regardless of what zone it was in.
    return valueWithCorrectTime.setZone(this.timeZone, { keepLocalTime: true });
  }

  private constrainToMinimum(value: DateTime | null): DateTime | null {
    if (
      this.minDateTime &&
      value &&
      value.toMillis() <= this.minDateTime.toMillis()
    ) {
      return this.minDateTime;
    }
    return value;
  }
}
