import * as t from "io-ts";
import { DateTime } from "luxon";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { UnloaderType } from "src/app/partner/appointments/base-appointment.model";
import { AppointmentDraft } from "src/app/partner/appointments/form/appointment-draft.model";
import { getEnumMember, parseDateTime } from "src/utils";
import { AppointmentChangesValue } from "./appointment-changes-value.model";

interface DeserializeArgs {
  readonly site: Site;
}

export class AppointmentChanges {
  private constructor(
    args: Omit<ClassProperties<AppointmentChanges>, "changeCount">,
  ) {
    this.carrier = args.carrier;
    this.effectiveDeliveryCarrierName = args.effectiveDeliveryCarrierName;
    this.isDropLoad = args.isDropLoad;
    this.isIntermodal = args.isIntermodal;
    this.loadType = args.loadType;
    this.notificationList = args.notificationList;
    this.schedule = args.schedule;
    this.slotDoor = args.slotDoor;
    this.slotStartTime = args.slotStartTime;
    this.totalCaseCount = args.totalCaseCount;
    this.totalLoadWeight = args.totalLoadWeight;
    this.totalWarehousePalletCount = args.totalWarehousePalletCount;
    this.unloader = args.unloader;

    this.changeCount = Object.values(args).filter(
      (arg) => arg.isChanged,
    ).length;
  }

  public readonly carrier: AppointmentChangesValue<string | null>;

  public readonly effectiveDeliveryCarrierName: AppointmentChangesValue<
    string | null
  >;

  public readonly isDropLoad: AppointmentChangesValue<
    AppointmentDraft["isDropLoad"]
  >;

  public readonly isIntermodal: AppointmentChangesValue<
    AppointmentDraft["isIntermodal"]
  >;

  public readonly loadType: AppointmentChangesValue<string | null>;

  public readonly notificationList: AppointmentChangesValue<
    AppointmentDraft["notificationList"]
  >;

  public readonly schedule: AppointmentChangesValue<
    AppointmentDraft["schedule"]
  >;

  public readonly slotDoor: AppointmentChangesValue<string | null>;

  public readonly slotStartTime: AppointmentChangesValue<DateTime | null>;

  public readonly totalCaseCount: AppointmentChangesValue<number | null>;

  public readonly totalLoadWeight: AppointmentChangesValue<
    AppointmentDraft["totalLoadWeight"]
  >;

  public readonly totalWarehousePalletCount: AppointmentChangesValue<
    number | null
  >;

  public readonly unloader: AppointmentChangesValue<
    AppointmentDraft["unloader"]
  >;

  public readonly changeCount: number;

  public static deserialize(
    apiModel: object,
    { site }: DeserializeArgs,
  ): AppointmentChanges {
    if (!changesCodec.is(apiModel)) {
      throw new Error("Unexpected appointment changes properties or values.");
    }

    return new AppointmentChanges({
      carrier: new AppointmentChangesValue({
        isChanged: "carrier" in apiModel,
        value: apiModel.carrier ?? null,
      }),
      effectiveDeliveryCarrierName: new AppointmentChangesValue({
        isChanged: "deliveryCarrier" in apiModel,
        value: apiModel.deliveryCarrier ?? null,
      }),
      isDropLoad: new AppointmentChangesValue({
        isChanged: "isDropload" in apiModel,
        value: apiModel.isDropload ?? null,
      }),
      isIntermodal: new AppointmentChangesValue({
        isChanged: "isIntermodal" in apiModel,
        value: apiModel.isIntermodal ?? null,
      }),
      loadType: new AppointmentChangesValue({
        isChanged: "doorGroup" in apiModel,
        value: apiModel.doorGroup ?? null,
      }),
      notificationList: new AppointmentChangesValue({
        isChanged: "notificationList" in apiModel,
        value: apiModel.notificationList || null,
      }),
      schedule: new AppointmentChangesValue({
        isChanged: "schedule" in apiModel,
        value: apiModel.schedule
          ? Day.deserialize(apiModel.schedule, site.timeZone)
          : null,
      }),
      slotDoor: new AppointmentChangesValue({
        isChanged: "door" in apiModel,
        value: apiModel.door ?? null,
      }),
      slotStartTime: new AppointmentChangesValue({
        isChanged: "slotStartTime" in apiModel,
        value: parseDateTime(apiModel.slotStartTime, site),
      }),
      totalCaseCount: new AppointmentChangesValue({
        isChanged: "caseCount" in apiModel,
        value: apiModel.caseCount ?? null,
      }),
      totalLoadWeight: new AppointmentChangesValue({
        isChanged: "loadWeight" in apiModel,
        value: apiModel.loadWeight ?? null,
      }),
      totalWarehousePalletCount: new AppointmentChangesValue({
        value: apiModel.totalPalletCount ?? null,
        isChanged: "totalPalletCount" in apiModel,
      }),
      unloader: new AppointmentChangesValue({
        isChanged: "unloader" in apiModel,
        value: apiModel.unloader
          ? getEnumMember(UnloaderType, apiModel.unloader)
          : null,
      }),
    });
  }
}

const changesCodec = t.partial({
  carrier: t.union([t.string, t.null]),
  caseCount: t.union([t.number, t.null]),
  deliveryCarrier: t.union([t.string, t.null]),
  door: t.union([t.string, t.null]),
  doorGroup: t.union([t.string, t.null]),
  effectiveTotalWarehousePalletCount: t.union([t.number, t.null]),
  isDropload: t.union([t.boolean, t.null]),
  isIntermodal: t.union([t.boolean, t.null]),
  loadWeight: t.union([t.number, t.null]),
  notificationList: t.union([t.string, t.null]),
  schedule: t.union([t.string, t.null]),
  slotStartTime: t.union([t.string, t.null]),
  totalPalletCount: t.union([t.number, t.null]),
  unloader: t.union([t.string, t.null]),
});
