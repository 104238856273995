<ng-container
  *ngIf="change?.isChanged || isExistent(currentValue); else notEntered"
>
  <ng-container *ngIf="change && change.isChanged; else currentValueDisplay">
    <mr-content-change kind="previous" *ngIf="isExistent(currentValue)">
      <ng-container *ngTemplateOutlet="currentValueDisplay"></ng-container>
    </mr-content-change>
    <mr-content-change kind="new" *ngIf="isExistent(change.value)">
      <ng-container
        *ngTemplateOutlet="valueDisplay; context: { value: change.value }"
      ></ng-container>
    </mr-content-change>
  </ng-container>
</ng-container>

<ng-template #currentValueDisplay>
  <ng-container
    *ngTemplateOutlet="valueDisplay; context: { value: currentValue }"
  ></ng-container>
</ng-template>

<ng-template #notEntered>
  <span class="text-italic">Not entered</span>
</ng-template>

<ng-template #valueDisplay let-value="value">
  <ng-template
    *ngIf="valueTemplate; else valueDefault"
    [ngTemplateOutlet]="valueTemplate"
    [ngTemplateOutletContext]="{ value: value }"
  >
  </ng-template>

  <ng-template #valueDefault>
    {{ value }}
  </ng-template>
</ng-template>
