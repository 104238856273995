import {
  Component,
  forwardRef,
  HostBinding,
  Input,
  Optional,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ValidatorFn } from "@angular/forms";
import { DateTime } from "luxon";
import { isInstanceOf, makeValidatorOptional } from "src/utils";
import { Day } from "../day.model";
import { TimeOfDay } from "../time-of-day.model";
import { BaseFieldComponent } from "./base-field.component";
import {
  FieldConfiguration,
  FieldContainerParentService,
} from "./field-container.component";

@Component({
  selector: "mr-date-picker[label]",
  template: `
    <mr-field-container
      #container
      [customFieldId]="fieldId"
      [label]="label"
      [description]="description"
      [labelHidden]="isLabelHidden"
      [kind]="kind"
      [additionalErrorMessageTemplate]="errorMessages"
    >
      <mr-date-time-picker-base
        [fieldId]="container.fieldId"
        [descriptionId]="container.descriptionId"
        [label]="isLabelHidden ? label : ''"
        [timeZone]="timeZone"
        [fixedTime]="fixedTime"
        [minDateTime]="minimumDateTime"
        [maxDateTime]="maximumDateTime"
        [disableClear]="disableClear"
        [disabled]="isDisabled"
        [readonly]="isReadonly"
        [value]="dateTime"
        (valueChange)="setFromDateTime($event)"
        (unfocus)="onBlur()"
      ></mr-date-time-picker-base>
    </mr-field-container>

    <ng-template #errorMessages let-errors let-errorLabel="errorLabel">
      <li *ngIf="errors.minDay as error">
        {{ errorLabel }} must be on or after {{ error.min | day }}
      </li>
      <li *ngIf="errors.maxDay as error">
        {{ errorLabel }} must be on or before {{ error.max | day }}
      </li>
    </ng-template>
  `,
  styles: [":host {display: block;}"],
  providers: [
    FieldContainerParentService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent),
    },
  ],
})
export class DatePickerComponent extends BaseFieldComponent<Day | null> {
  public constructor(
    @Optional() private readonly config: FieldConfiguration | null,
  ) {
    super();
  }

  @Input() public disableClear = false;
  @Input() public kind: "form" | "tiled" = "form";

  @Input() public set minDay(value: Day | null) {
    this.minimumDateTime =
      value?.toDateTime(Day.startOfDay, this.timeZone) ?? null;
  }
  public minimumDateTime: DateTime | null = null;

  @Input() public set maxDay(value: Day | null) {
    this.maximumDateTime =
      value?.toDateTime(Day.startOfDay, this.timeZone) ?? null;
  }
  public maximumDateTime: DateTime | null = null;

  @HostBinding("class.editing") public get isEditingExisting(): boolean {
    return this.config?.isEditingExisting ?? false;
  }

  public readonly timeZone = Day.localZone;
  public readonly fixedTime = TimeOfDay.midnight;

  public override get value(): Day | null {
    return this._value;
  }
  public override set value(value: Day | null) {
    this._value = value;
    this.dateTime = value?.toDateTime(this.fixedTime, this.timeZone) ?? null;
  }
  private _value: Day | null = null;
  public dateTime: DateTime | null = null;

  public setFromDateTime(dateTime: DateTime | null): void {
    this.onChange(dateTime && new Day(dateTime));
  }
}

type MinDayError = ["minDay", { min: Day; actual: Day }];
export function minDayValidator(min: Day): ValidatorFn {
  return makeValidatorOptional<MinDayError>(isInstanceOf(Day), (actual) =>
    actual.isBefore(min) ? { minDay: { min, actual } } : null,
  );
}

type MaxDayError = ["maxDay", { max: Day; actual: Day }];
export function maxDayValidator(max: Day): ValidatorFn {
  return makeValidatorOptional<MaxDayError>(isInstanceOf(Day), (actual) =>
    actual.isAfter(max) ? { maxDay: { max, actual } } : null,
  );
}
