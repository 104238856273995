import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { orderBy, uniq } from "lodash-es";
import { DateTime, Duration, Interval } from "luxon";
import { GlobalSite } from "src/app/carrier/global-site.model";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import {
  AppointmentStart,
  getSiteRouteUrl,
  Site,
  SiteModelReference,
} from "src/app/core/sites";
import { DropdownColumnFilterValue } from "src/app/core/table";
import {
  Carrier,
  deserializeSimpleCarrier,
  SimpleCarrier,
} from "src/app/partner/global/carriers";
import { HelpAssistTicketStatus } from "src/app/partner/help-assist/help-assist-enums";
import { HelpAssistTicketReference } from "src/app/partner/help-assist/models/ticket/base-help-assist-ticket.model";
import {
  BasePurchaseOrder,
  getPurchaseOrderRouteUrl,
} from "src/app/partner/purchase-orders/base-purchase-order.model";
import {
  getMainPurchaseOrder,
  pickAppointmentDurationSettings,
} from "src/app/partner/purchase-orders/purchase-order-list.model";
import { PurchaseOrder } from "src/app/partner/purchase-orders/purchase-order.model";
import { Dock } from "src/app/partner/settings/docks/dock.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import { Door } from "src/app/partner/settings/doors/door.model";
import { Reservation } from "src/app/partner/settings/reservations/reservation.model";
import { getVendorRouteUrl } from "src/app/partner/vendors/base-vendor.model";
import {
  deserializeVendorDisplayName,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import {
  clampIntervalDuration,
  ExpandedODataModel,
  getApiDetailsDecorator,
  getEnumMember,
  isExistent,
  parseDateTime,
  parseDuration,
  snapToStep,
  throwUnhandledCaseError,
} from "src/utils";
import { AppointmentSchedule } from "./appointment-schedule.model";
import {
  AppointmentStatus,
  AppointmentStatusCode,
} from "./appointment-status.model";
import { AppointmentTicket } from "./appointment-ticket.model";
import { AppointmentReservationSlot } from "./fields/appointment-reservation-slot.model";
import { AppointmentSlot } from "./fields/appointment-slot.model";

const api =
  getApiDetailsDecorator<
    ExpandedODataModel<
      Api.Appointment,
      "doors" | "helpAssistTicketAppointmentSchedules" | "orders"
    >
  >();

export abstract class BaseAppointment<
  Schedule extends SimpleAppointmentSchedule,
> implements AppointmentReference
{
  protected constructor(args: BaseAppointmentArguments<Schedule>) {
    this.appointmentSchedules = orderBy(
      args.appointmentSchedules,
      ({ ticket }) => ticket.id,
      "desc",
    );
    this.carrierETA = args.carrierETA;
    this.carrierOfRecord = args.carrierOfRecord;
    this.comments = args.comments;
    this.confirmationNumber = args.confirmationNumber;
    this.consigneeCode = args.consigneeCode;
    this.cube = args.cube;
    this.deliveryCarrier = args.deliveryCarrier;
    this.doors = args.doors;
    this.driverCDLNumber = args.driverCDLNumber;
    this.driverName = args.driverName;
    this.driverPhoneNumber = args.driverPhoneNumber;
    this.dueDate = args.dueDate;
    this.gateInTimestamp = args.gateInTimestamp;
    this.gateOutTimestamp = args.gateOutTimestamp;
    this.id = args.id;
    this.isDropLoad = args.isDropLoad;
    this.isIntermodal = args.isIntermodal;
    this.isPimTagged = args.isPimTagged;
    this.isUnscheduledSameDay = args.isUnscheduledSameDay;
    this.lateGateInFlag = args.lateGateInFlag;
    this.lateGateOutFlag = args.lateGateOutFlag;
    this.loadNumber = args.loadNumber;
    this.loadType = args.loadType;
    this.offComplexTimestamp = args.offComplexTimestamp;
    this.onComplexTimestamp = args.onComplexTimestamp;
    this.orders = orderBy(
      args.orders,
      (order) => order.warehousePalletCount,
      "desc",
    );
    this.originalAppointmentDate = args.originalAppointmentDate;
    this.priority = args.priority;
    this.reservation = args.reservation;
    this.scheduledArrivalTime = args.scheduledArrivalTime;
    this.scheduledDuration = args.scheduledDuration;
    this.site = args.site;
    this.slotWasReserved = args.slotWasReserved;
    this.status = args.status;
    this.totalCaseCount = args.totalCaseCount;
    this.totalLoadWeight = args.totalLoadWeight;
    this.totalWarehousePalletCount = args.totalWarehousePalletCount;
    this.totalWarehousePalletCountOverride =
      args.totalWarehousePalletCountOverride;
    this.trailer = args.trailer;
    this.trailerTemperatureActual = args.trailerTemperatureActual;
    this.trailerTemperatureSet = args.trailerTemperatureSet;
    this.unknownDeliveryCarrierName = args.unknownDeliveryCarrierName;
    this.unloadEndTimestamp = args.unloadEndTimestamp;
    this.unloader = args.unloader;
    this.unloadStartTimestamp = args.unloadStartTimestamp;
    this.vendor = args.vendor;
    this.yardLocation = args.yardLocation;

    // Computed Properties

    // Per business rules, every door in the appointment is guaranteed to be at
    // the same dock and door group, so arbitrarily pick the first door if it
    // exists. Otherwise, there isn't an associated dock or door group.
    const { dock = null, doorGroup = null } = this.doors[0] ?? {};

    this.latestPendingApprovalTicketSchedule =
      getLatestPendingApprovalTicketSchedule(this.appointmentSchedules);

    this.mainPurchaseOrder = getMainPurchaseOrder(this.orders);

    this.dock = dock;
    this.doorGroup = doorGroup;
    this.effectiveDeliveryCarrierName =
      this.deliveryCarrier?.name ?? this.unknownDeliveryCarrierName;

    this.effectiveStartTime = snapToDurationStep(
      this.gateInTimestamp ?? this.scheduledArrivalTime,
      this.site.appointmentInterval,
    );
    this.hasGateInStatus = isGateInStatus(this.status);
    this.hasGateOutStatus = isGateOutStatus(this.status);
    this.hasPendingApprovalTickets =
      this.latestPendingApprovalTicketSchedule !== null;
    this.isBackhaul =
      this.mainPurchaseOrder?.managedType === ManagedType.Backhaul;
    this.isCFM = args.isCFM;
    this.isCompleted = isAppointmentCompleted(this);
    this.isModifiable = modifiableStatuses.includes(this.status.code);
    this.isMovable = movableStatuses.includes(this.status.code);
    this.latestApprovalTicket =
      this.appointmentSchedules?.find(
        (schedule) => schedule.isAppointmentApprovalRequired,
      )?.ticket ?? null;
    this.latestOpenTicket = getLatestOpenTicket(this.appointmentSchedules);
    this.latestPendingApprovalTicket =
      this.latestPendingApprovalTicketSchedule?.ticket ?? null;
    this.managedTypes = uniq(
      this.orders?.map(({ managedType }) => managedType).filter(isExistent),
    );
    this.scheduledDay = new Day(this.scheduledArrivalTime);
    this.scheduledEndTime = this.scheduledArrivalTime.plus(
      this.scheduledDuration,
    );
    this.schedule = this.scheduledArrivalTime.until(this.scheduledEndTime);
    this.slot = this.reservation
      ? AppointmentReservationSlot.create({
          doors: this.doors,
          request: { isDropLoad: this.isDropLoad, orders: this.orders },
          reservation: this.reservation,
          startTime: this.scheduledArrivalTime,
        })
      : AppointmentSlot.create({
          doors: this.doors,
          startTime: this.scheduledArrivalTime,
        });
  }

  @api({ key: "helpAssistTicketAppointmentSchedules" })
  public readonly appointmentSchedules: readonly Schedule[] | null;
  @api({ key: "carrierETA" }) public readonly carrierETA: DateTime | null;
  @api({ key: "carrierID", navigationProperty: "carrier", uiModel: Carrier })
  public readonly carrierOfRecord: SimpleCarrier;
  @api({ key: "appointmentComments" }) public readonly comments: string | null;
  @api() public readonly confirmationNumber: string;
  @api() public readonly consigneeCode: string | null;
  @api() public readonly cube: number | null;
  @api({
    key: "deliveryCarrierRecordID",
    navigationProperty: "deliveryCarrierRecord",
    uiModel: Carrier,
  })
  public readonly deliveryCarrier: SimpleCarrier | null;
  public readonly dock: Dock | null;
  public readonly doorGroup: DoorGroup | null;
  @api({ key: "doors", uiModel: Door }) public readonly doors: readonly Door[];
  @api() public readonly driverCDLNumber: string | null;
  @api() public readonly driverName: string | null;
  @api() public readonly driverPhoneNumber: string | null;
  @api() public readonly dueDate: DateTime | null;
  public readonly effectiveDeliveryCarrierName: string | null;
  /** The start (actual or scheduled) as appropriate for the appointment status. */
  public readonly effectiveStartTime: DateTime;
  @api({ key: "gateInTime" }) public readonly gateInTimestamp: DateTime | null;
  @api({ key: "gateOutTime" })
  public readonly gateOutTimestamp: DateTime | null;
  public readonly hasGateInStatus: boolean;
  public readonly hasGateOutStatus: boolean;
  public readonly hasPendingApprovalTickets: boolean;
  @api() public readonly id: AppointmentReference["id"];
  public readonly isBackhaul: boolean;
  // The service handles this computed property as a special case for
  // an existence check on the ID property.
  @api({ key: "cfmAppointmentId" }) public readonly isCFM: boolean;
  /**
   * Whether the appointment is considered fully complete and the truck is out
   * of the system.
   */
  public readonly isCompleted: boolean;
  @api({ key: "dropLoad" }) public readonly isDropLoad: boolean;
  @api({ key: "intermodal" }) public readonly isIntermodal: boolean;
  public readonly isModifiable: boolean;
  public readonly isMovable: boolean;
  @api({ key: "pimTagFlag" }) public readonly isPimTagged: boolean | null;
  @api({ key: "isSameDay" })
  public readonly isUnscheduledSameDay: boolean | null;
  @api() public readonly lateGateInFlag: boolean | null;
  @api() public readonly lateGateOutFlag: boolean | null;
  /** The latest approval ticket, no matter its status. */
  public readonly latestApprovalTicket: Schedule["ticket"] | null;
  /** The latest open ticket associated with this appointment. */
  public readonly latestOpenTicket: Schedule["ticket"] | null;
  /** The latest approval ticket with a pending status. */
  public readonly latestPendingApprovalTicket: Schedule["ticket"] | null;
  @api({ key: "clientAppointmentNumber" })
  public readonly loadNumber: string | null;
  @api() public readonly loadType: string | null;
  public readonly managedTypes: readonly ManagedType[];
  @api({ key: "offComplexTime" })
  public readonly offComplexTimestamp: DateTime | null;
  @api({ key: "onComplexTime" })
  public readonly onComplexTimestamp: DateTime | null;
  @api() public readonly originalAppointmentDate: DateTime | null;
  @api({
    key: "mainOrderId",
    navigationProperty: "mainOrder",
    uiModel: BasePurchaseOrder,
  })
  public readonly mainPurchaseOrder: SimpleAppointmentPurchaseOrder | null;
  @api() public readonly orders: readonly SimpleAppointmentPurchaseOrder[];
  @api() public readonly priority: string | null;
  @api({ key: "reservationID", uiModel: Reservation })
  public readonly reservation: Reservation | null;
  public readonly schedule: Interval;
  @api({ key: "startTime" }) public readonly scheduledArrivalTime: DateTime;
  public readonly scheduledDay: Day;
  @api() public readonly scheduledDuration: Duration;
  /**
   * The expected end time of the appointment based on the scheduled arrival
   * time and the calculated or configured duration.
   */
  public readonly scheduledEndTime: DateTime;
  @api({ key: "siteID", navigationProperty: "site", uiModel: Site })
  public readonly site: Site;
  public readonly slot: AppointmentSlot | AppointmentReservationSlot;
  @api() public readonly slotWasReserved: boolean;
  @api({
    key: "appointmentStatusID",
    navigationProperty: "appointmentStatus",
    uiModel: AppointmentStatus,
  })
  public readonly status: AppointmentStatus;
  @api() public readonly totalCaseCount: number;
  @api() public readonly totalLoadWeight: number | null;
  @api({ key: "totalPalletCount" })
  public readonly totalWarehousePalletCount: number;
  @api({ key: "appointmentPalletOverride" })
  public readonly totalWarehousePalletCountOverride: number | null;
  @api() public readonly trailer: string | null;
  @api() public readonly trailerTemperatureActual: number | null;
  @api() public readonly trailerTemperatureSet: number | null;
  @api({ key: "deliveryCarrier" })
  public readonly unknownDeliveryCarrierName: string | null;
  @api({ key: "mmsUnloadEndTime" })
  public readonly unloadEndTimestamp: DateTime | null;
  @api() public readonly unloader: UnloaderType;
  @api({ key: "mmsUnloadStartTime" })
  public readonly unloadStartTimestamp: DateTime | null;
  @api({ key: "vendorID", navigationProperty: "vendor", uiModel: Vendor })
  public readonly vendor: AppointmentVendor | null;
  @api() public readonly yardLocation: string | null;

  protected readonly latestPendingApprovalTicketSchedule: Schedule | null;

  protected static deserializeBase(
    data: Omit<Api.ScheduledAppointment, "lastActivity" | "tickets">,
    {
      doors: allDoors,
      reservations,
      site,
      statuses,
    }: BaseAppointmentDeserializeArguments,
  ): Omit<BaseAppointmentArguments<never>, "appointmentSchedules"> {
    const doors = data.doors.map((apiDoor) => {
      const door = allDoors.find(({ id }) => id === apiDoor.doorID);
      if (!door) {
        throw new Error(`Could not find door with ID "${apiDoor.doorID}".`);
      }
      return door;
    });

    const orders = data.orders.map((order) =>
      deserializeSimpleAppointmentPurchaseOrder(
        { ...order, id: order.orderID },
        { site },
      ),
    );

    let reservation: Reservation | null = null;
    if (isExistent(data.reservationID)) {
      const maybeReservation = reservations.find(
        ({ id }) => id === data.reservationID,
      );
      if (!maybeReservation) {
        throw new Error(
          `Could not find reservation with ID "${data.reservationID}".`,
        );
      }
      reservation = maybeReservation;
    }

    return {
      carrierETA: parseDateTime(data.carrierETA, site),
      carrierOfRecord: deserializeSimpleCarrier({
        id: data.carrier.carrierID,
        name: data.carrier.carrierName,
      }),
      comments: data.appointmentComments || null,
      confirmationNumber: data.confirmationNumber,
      consigneeCode: data.consigneeCode || null,
      cube: data.cube ?? null,
      deliveryCarrier: data.deliveryCarrierRecord
        ? deserializeSimpleCarrier({
            id: data.deliveryCarrierRecord.carrierID,
            name: data.deliveryCarrierRecord.carrierName,
          })
        : null,
      doors,
      driverCDLNumber: data.driverCDLNumber || null,
      driverName: data.driverName || null,
      driverPhoneNumber: data.driverPhoneNumber || null,
      dueDate: parseDateTime(data.dueDate, site),
      gateInTimestamp: parseDateTime(data.gateInTime, site),
      gateOutTimestamp: parseDateTime(data.gateOutTime, site),
      id: data.id,
      isCFM: isExistent(data.cfmAppointmentId),
      isDropLoad: data.dropLoad,
      isIntermodal: data.intermodal,
      isPimTagged: data.pimTagFlag ?? null,
      isUnscheduledSameDay: data.isSameDay ?? null,
      lateGateInFlag: data.lateGateInFlag ?? null,
      lateGateOutFlag: data.lateGateOutFlag ?? null,
      loadNumber: data.clientAppointmentNumber || null,
      loadType: data.loadType || null,
      offComplexTimestamp: parseDateTime(data.offComplexTime, site),
      onComplexTimestamp: parseDateTime(data.onComplexTime, site),
      orders,
      originalAppointmentDate: parseDateTime(
        data.originalAppointmentDate,
        site,
      ),
      priority: data.priority || null,
      reservation,
      scheduledArrivalTime: parseDateTime(data.startTime, site),
      scheduledDuration: parseDuration(data.scheduledDuration, "minutes"),
      site,
      slotWasReserved: data.slotWasReserved,
      status: deserializeAppointmentStatus(data, { statuses }),
      totalCaseCount: data.totalCaseCount,
      totalLoadWeight: data.totalLoadWeight ?? null,
      totalWarehousePalletCount: data.totalPalletCount,
      totalWarehousePalletCountOverride: data.appointmentPalletOverride ?? null,
      trailer: data.trailer || null,
      trailerTemperatureActual: data.trailerTemperatureActual ?? null,
      trailerTemperatureSet: data.trailerTemperatureSet ?? null,
      unknownDeliveryCarrierName: data.deliveryCarrier || null,
      unloadEndTimestamp: parseDateTime(data.mmsUnloadEndTime, site),
      unloader: getEnumMember(UnloaderType, data.unloader),
      unloadStartTimestamp: parseDateTime(data.mmsUnloadStartTime, site),
      vendor: data.vendor
        ? deserializeAppointmentVendor(
            {
              id: data.vendor.vendorID,
              name: data.vendor.vendorName,
              vendorNumber: data.vendor.vendorNumber,
            },
            { site },
          )
        : null,
      yardLocation: data.yardLocation || null,
    };
  }

  /** Whether the current time is before the earliest allowed arrival time. */
  public isEarlyArrival(): boolean {
    return !this.site.earlyArrivalDuration || this.isBackhaul || this.isDropLoad
      ? false
      : DateTime.utc() <
          this.scheduledArrivalTime.minus(this.site.earlyArrivalDuration);
  }

  public isScheduledForToday(): boolean {
    return this.scheduledDay.isTodayIn(this.site.timeZone);
  }

  public isScheduledInTheFuture(): boolean {
    const today = Day.getTodayIn(this.site.timeZone);
    return today.isBefore(this.scheduledDay);
  }

  /**
   * Gets whether the appointment has missed its scheduled arrival time without
   * being started.
   */
  public isLate(): boolean {
    const now = DateTime.utc();
    return (
      this.status.code === AppointmentStatusCode.Open &&
      now > this.scheduledArrivalTime.plus(this.site.lateArrivalDuration)
    );
  }

  /**
   * Gets whether the appointment has run over its scheduled duration without
   * being completed.
   */
  public isOverSchedule(): boolean {
    const startTime = getTimerStartActualTime(this);
    return (
      // We only want to check the over-schedule status while the truck is at
      // the gate. Otherwise, they'll stay red even after off-complex.
      this.status.code === AppointmentStatusCode.GateIn &&
      // If there's no start time, then we haven't reached that timestamp yet,
      // so it can't be over-schedule.
      startTime !== null &&
      startTime.diffNow().negate() > this.scheduledDuration
    );
  }

  /**
   * Gets the start and end times for the actual, possibly ongoing schedule
   * starting from the real start of the appointment, **not** the scheduled
   * start.
   */
  public getActualSchedule(): Interval | null {
    const now = DateTime.utc();
    if (this.gateInTimestamp && this.gateOutTimestamp) {
      return this.gateInTimestamp <= this.gateOutTimestamp
        ? this.gateInTimestamp.until(this.gateOutTimestamp)
        : null;
    } else if (this.gateInTimestamp && this.gateInTimestamp <= now) {
      return this.gateInTimestamp.until(now);
    } else {
      return null;
    }
  }

  /**
   * Gets the actual, possibly ongoing duration starting from the real start of
   * the appointment, **not** the scheduled start.
   */
  public getActualDuration(): Duration | null {
    return this.getActualSchedule()?.toDuration() ?? null;
  }

  public getEffectiveSchedule(): Interval {
    const actualSchedule = this.getActualSchedule();
    const effectiveSchedule = snapScheduleToDurationStep(
      this.site,
      this.status.code,
      this.dock,
      actualSchedule || this.schedule,
      isExistent(this.reservation),
    );

    if (this.status.code === AppointmentStatusCode.GateIn) {
      return clampIntervalDuration(effectiveSchedule, {
        min: this.scheduledDuration,
      });
    }

    return effectiveSchedule;
  }

  /**
   * Gets the effective schedule of this appointment **if** it were to be
   * adjusted with the given values.
   * @param options.startTime - The theoretical new start time.
   * @param options.endTime - The theoretical new end time.
   */
  public getEffectiveScheduleWith({
    startTime = this.effectiveStartTime,
    endTime = startTime.plus(this.getEffectiveDuration()),
  }: {
    startTime?: DateTime;
    endTime?: DateTime;
  }): Interval {
    const schedule = snapScheduleToDurationStep(
      this.site,
      this.status.code,
      this.dock,
      // Prevent end time from being before the start time.
      startTime.until(DateTime.max(startTime, endTime)),
      isExistent(this.reservation),
    );

    const theoreticalAppointment = this.cloneWith({
      ...this,
      scheduledArrivalTime: schedule.start,
      scheduledDuration: schedule.toDuration(),
    });
    return theoreticalAppointment.getEffectiveSchedule();
  }

  /**
   * Gets the appointment duration from the effective start time to the
   * effective end time. That is, the duration either from the actual or
   * scheduled start time until the appropriate end time (or current time) as
   * appropriate for the status.
   */
  public getEffectiveDuration(): Duration {
    return this.getEffectiveSchedule().toDuration();
  }

  /**
   * Gets whether the unscheduled same day flag would have to be updated if the
   * appointment were to be scheduled for the provided day.
   * @param day - the day to check
   */
  public mustUpdateUnscheduledSameDayFor(day: Day): boolean {
    return (
      !day.isSameDay(this.scheduledDay) &&
      this.site.mustUpdateAppointmentUnscheduledSameDayFor(day)
    );
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getAppointmentRouteUrl(this, ...childPaths);
  }

  protected abstract cloneWith(
    args: BaseAppointmentArguments<Schedule>,
  ): BaseAppointment<Schedule>;
}

export function getAppointmentRouteUrl(
  appointment: AppointmentReference,
  ...childPaths: string[]
): string {
  return getSiteRouteUrl(
    appointment.site,
    "appointments",
    String(appointment.id),
    ...childPaths,
  );
}

export interface AppointmentReference
  extends SiteModelReference<"appointment"> {}

export type BaseAppointmentUpdateArguments =
  ClassProperties<BaseAppointmentUpdate>;

export abstract class BaseAppointmentUpdate {
  protected constructor(args: BaseAppointmentUpdateArguments) {
    this.carrierETA = args.carrierETA;
    this.carrierOfRecord = args.carrierOfRecord;
    this.comments = args.comments;
    this.contactEmail = args.contactEmail;
    this.contactName = args.contactName;
    this.contactPhone = args.contactPhone;
    this.deliveryCarrier = args.deliveryCarrier;
    this.driverCDLNumber = args.driverCDLNumber;
    this.driverName = args.driverName;
    this.driverPhoneNumber = args.driverPhoneNumber;
    this.gateInTimestamp = args.gateInTimestamp;
    this.gateOutTimestamp = args.gateOutTimestamp;
    this.isDropLoad = args.isDropLoad;
    this.isEditRoute = args.isEditRoute ?? false;
    this.isIntermodal = args.isIntermodal;
    this.isUnscheduledSameDay = args.isUnscheduledSameDay;
    this.loadType = args.loadType;
    this.notificationList = args.notificationList;
    this.offComplexTimestamp = args.offComplexTimestamp;
    this.onComplexTimestamp = args.onComplexTimestamp;
    this.reservation = args.reservation;
    this.scheduledArrivalTime = args.scheduledArrivalTime;
    this.scheduledDuration = args.scheduledDuration;
    this.site = args.site;
    this.slotWasReserved = args.slotWasReserved;
    this.status = args.status;
    this.totalLoadWeight = args.totalLoadWeight;
    this.totalWarehousePalletCountOverride =
      args.totalWarehousePalletCountOverride;
    this.trailer = args.trailer;
    this.trailerTemperatureActual = args.trailerTemperatureActual;
    this.trailerTemperatureSet = args.trailerTemperatureSet;
    this.unknownDeliveryCarrierName = args.unknownDeliveryCarrierName;
    this.unloadEndTimestamp = args.unloadEndTimestamp;
    this.unloader = args.unloader;
    this.unloadNotificationTimestamp = args.unloadEndTimestamp;
    this.unloadStartTimestamp = args.unloadStartTimestamp;
  }

  @api({ key: "carrierID", navigationProperty: "carrier", uiModel: Carrier })
  public readonly carrierOfRecord: SimpleCarrier;
  @api({ key: "carrierETA" }) public readonly carrierETA: DateTime | null;
  @api({ key: "appointmentComments" }) public readonly comments: string | null;
  @api() public readonly contactEmail: string | null;
  @api() public readonly contactName: string | null;
  @api() public readonly contactPhone: string | null;
  @api({
    key: "deliveryCarrierRecordID",
    navigationProperty: "deliveryCarrierRecord",
    uiModel: Carrier,
  })
  public readonly deliveryCarrier: SimpleCarrier | null;
  @api() public readonly driverName: string | null;
  @api() public readonly driverPhoneNumber: string | null;
  @api() public readonly driverCDLNumber: string | null;
  @api({ key: "gateInTime" }) public readonly gateInTimestamp: DateTime | null;
  @api({ key: "gateOutTime" })
  public readonly gateOutTimestamp: DateTime | null;
  @api({ key: "dropLoad" }) public readonly isDropLoad: boolean;
  public isEditRoute?: boolean;
  @api({ key: "intermodal" }) public readonly isIntermodal: boolean;
  @api({ key: "isSameDay" })
  public readonly isUnscheduledSameDay: boolean | null;
  @api() public readonly loadType: string | null;
  @api() public readonly notificationList: string | null;
  @api({ key: "onComplexTime" })
  public readonly onComplexTimestamp: DateTime | null;
  @api({ key: "offComplexTime" })
  public readonly offComplexTimestamp: DateTime | null;
  @api({ key: "reservationID" })
  public readonly reservation: Reservation | null;
  @api({ key: "startTime" }) public readonly scheduledArrivalTime: DateTime;
  @api() public readonly scheduledDuration: Duration;
  @api({ key: "siteID" }) public readonly site: Site | GlobalSite;
  @api() public readonly slotWasReserved: boolean;
  @api({
    key: "appointmentStatusID",
    navigationProperty: "appointmentStatus",
    uiModel: AppointmentStatus,
  })
  public readonly status: AppointmentStatus;
  @api() public readonly totalLoadWeight: number | null;
  @api({ key: "appointmentPalletOverride" })
  public readonly totalWarehousePalletCountOverride: number | null;
  @api() public readonly trailer: string | null;
  @api() public readonly trailerTemperatureActual: number | null;
  @api() public readonly trailerTemperatureSet: number | null;
  @api({ key: "deliveryCarrier" })
  public readonly unknownDeliveryCarrierName: string | null;
  @api({ key: "mmsUnloadEndTime" })
  public readonly unloadEndTimestamp: DateTime | null;
  @api() public readonly unloader: UnloaderType;
  @api({ key: "mmsReceivedTime" })
  public readonly unloadNotificationTimestamp: DateTime | null;
  @api({ key: "mmsUnloadStartTime" })
  public readonly unloadStartTimestamp: DateTime | null;
}

export interface SimpleAppointmentPurchaseOrder
  extends Pick<
    PurchaseOrder,
    | "asnBillOfLadingNumber"
    | "asnProNumber"
    | "billOfLadingNumber"
    | "dueDate"
    | "freightBasis"
    | "id"
    | "loadNumber"
    | "managedType"
    | "number"
    | "proNumber"
    | "suggestedDoor"
    | "tempZone"
    | "warehousePalletCount"
  > {
  getRouteUrl(...childPaths: string[]): string;
}

export type BaseAppointmentArguments<
  Schedule extends SimpleAppointmentSchedule,
> = Omit<
  ClassProperties<BaseAppointment<Schedule>>,
  BaseAppointmentComputedProperties
>;

export interface SimpleAppointmentTicket
  extends HelpAssistTicketReference,
    Pick<AppointmentTicket, "status"> {
  readonly ownerUser: Pick<AppointmentTicket["ownerUser"], "userId">;
  getRouteUrl(...childPaths: string[]): string;
}

export interface SimpleAppointmentSchedule
  extends Pick<AppointmentSchedule, "isAppointmentApprovalRequired"> {
  readonly ticket: SimpleAppointmentTicket;
}

export type BaseAppointmentComputedProperties =
  | "dock"
  | "doorGroup"
  | "effectiveDeliveryCarrierName"
  | "effectiveStartTime"
  | "hasGateInStatus"
  | "hasGateOutStatus"
  | "hasPendingApprovalTickets"
  | "isBackhaul"
  | "isCompleted"
  | "isModifiable"
  | "isMovable"
  | "latestApprovalTicket"
  | "latestOpenTicket"
  | "latestPendingApprovalTicket"
  | "mainPurchaseOrder"
  | "managedTypes"
  | "schedule"
  | "scheduledDay"
  | "scheduledEndTime"
  | "slot";

interface BaseAppointmentDeserializeArguments {
  readonly doors: readonly Door[];
  readonly reservations: readonly Reservation[];
  readonly site: Site;
  readonly statuses: readonly AppointmentStatus[];
}

export enum UnloaderType {
  Capstone = "Capstone",
  Partner = "Partner",
  Driver = "Driver",
}

type ApiAppointmentPurchaseOrder = Pick<
  Api.PurchaseOrder,
  | "asnbolNumber"
  | "asnproNumber"
  | "bolNumber"
  | "clientAppointmentNumber"
  | "dueDate"
  | "freightBasis"
  | "id"
  | "managedType"
  | "palletCount"
  | "poNumber"
  | "proNumber"
  | "suggestedDoor"
  | "tempZone"
>;

interface DeserializeArguments {
  readonly site: Site | GlobalSite;
}

export function deserializeSimpleAppointmentPurchaseOrder(
  data: ApiAppointmentPurchaseOrder,
  { site }: DeserializeArguments,
): SimpleAppointmentPurchaseOrder {
  return {
    asnBillOfLadingNumber: data.asnbolNumber ?? null,
    asnProNumber: data.asnproNumber ?? null,
    billOfLadingNumber: data.bolNumber ?? null,
    dueDate: data.dueDate ? Day.deserialize(data.dueDate, site.timeZone) : null,
    freightBasis: data.freightBasis ?? null,
    getRouteUrl: (...childPaths) =>
      getPurchaseOrderRouteUrl({ id: data.id, site }, ...childPaths),
    id: data.id,
    loadNumber: data.clientAppointmentNumber ?? null,
    managedType: getEnumMember(ManagedType, data.managedType, null),
    number: data.poNumber,
    proNumber: data.proNumber ?? null,
    suggestedDoor: data.suggestedDoor ?? null,
    tempZone: data.tempZone ?? null,
    warehousePalletCount: data.palletCount,
  };
}

export function deserializeAppointmentStatus(
  data: Pick<Api.Appointment, "appointmentStatusID">,
  { statuses }: { statuses: readonly AppointmentStatus[] },
): AppointmentStatus {
  const { appointmentStatusID } = data;

  if (isExistent(appointmentStatusID)) {
    const maybeStatus = statuses.find(({ id }) => id === appointmentStatusID);
    if (!maybeStatus) {
      throw new Error(
        `Could not find appointment status type with ID "${appointmentStatusID}".`,
      );
    }
    return maybeStatus;
  }

  return AppointmentStatus.unknown;
}

export function deserializeAppointmentVendor(
  data: Pick<Api.Vendor, "id" | "name" | "vendorNumber">,
  { site }: DeserializeArguments,
): AppointmentVendor {
  return {
    ...deserializeVendorDisplayName({
      name: data.name,
      vendorNumber: data.vendorNumber,
    }),
    getRouteUrl: (...childPaths) =>
      getVendorRouteUrl({ id: data.id, site }, ...childPaths),
    id: data.id,
    name: data.name,
    number: data.vendorNumber,
  };
}

interface UnloaderTypeOption extends DropdownColumnFilterValue {
  readonly id: UnloaderType;
  readonly name: string;
}
export function getUnloaderTypeOptions(
  args?:
    | Site
    | GlobalSite
    | {
        isDropLoad?: boolean | null;
        isPartnerExcluded?: boolean;
        site: Site | GlobalSite;
      },
): readonly UnloaderTypeOption[] {
  const {
    isDropLoad = false,
    isPartnerExcluded = false,
    site = null,
  } = args instanceof Site || args instanceof GlobalSite
    ? { site: args }
    : args ?? {};

  const options = [{ id: UnloaderType.Capstone, name: "Capstone" }];

  if (!isPartnerExcluded) {
    options.push({
      id: UnloaderType.Partner,
      name: site?.partner.name ?? "Partner",
    });
  }

  if (!isDropLoad) {
    options.push({ id: UnloaderType.Driver, name: "Driver" });
  }

  return options;
}

export interface AppointmentVendor
  extends Pick<Vendor, "displayName" | "id" | "name" | "number"> {
  getRouteUrl(...childPaths: string[]): string;
}

function isGateInStatus(status: AppointmentStatus): boolean {
  return gateInStatuses.includes(status.code);
}

function isGateOutStatus(status: AppointmentStatus): boolean {
  return gateOutStatuses.includes(status.code);
}

export function isAppointmentCompleted({
  site,
  status,
}: Pick<BaseAppointment<never>, "site" | "status">): boolean {
  return (
    status.code === AppointmentStatusCode.OffComplex ||
    (!site.isOffComplexAppointmentStatusEnabled && isGateOutStatus(status))
  );
}

function snapScheduleToDurationStep(
  site: Site,
  appointmentStatusCode: AppointmentStatusCode,
  dock: Dock | null,
  interval: Interval,
  isReservation: boolean,
): Interval {
  const isAppointmentOpen =
    appointmentStatusCode === AppointmentStatusCode.Open;

  dock = isAppointmentOpen ? dock : null;

  const { appointmentInterval } = isReservation
    ? site
    : pickAppointmentDurationSettings(site, dock);

  const snappedStart = snapToDurationStep(interval.start, appointmentInterval);
  const snappedEnd = snapToDurationStep(interval.end, appointmentInterval);

  const earliestEnd = snappedStart.plus(appointmentInterval);
  return snappedStart.until(DateTime.max(snappedEnd, earliestEnd));
}

function snapToDurationStep(dateTime: DateTime, step: Duration): DateTime {
  const minutes = snapToStep(dateTime.minute, step.as("minutes"));
  return dateTime.startOf("hour").plus({ minutes });
}

function getLatestOpenTicket<T extends SimpleAppointmentSchedule>(
  appointmentSchedules: readonly T[] | null,
): T["ticket"] | null {
  const appointmentSchedule = appointmentSchedules?.find(
    (schedule) =>
      schedule.ticket.status !== HelpAssistTicketStatus.Closed &&
      schedule.ticket.status !== HelpAssistTicketStatus.Expired,
  );
  return appointmentSchedule?.ticket ?? null;
}

function getLatestPendingApprovalTicketSchedule<
  T extends SimpleAppointmentSchedule,
>(appointmentSchedules: readonly T[] | null): T | null {
  return (
    appointmentSchedules?.find(
      (schedule) =>
        schedule.ticket.status !== HelpAssistTicketStatus.Closed &&
        schedule.ticket.status !== HelpAssistTicketStatus.Expired &&
        schedule.isAppointmentApprovalRequired,
    ) ?? null
  );
}

function getTimerStartActualTime<T extends SimpleAppointmentSchedule>({
  gateInTimestamp,
  onComplexTimestamp,
  scheduledArrivalTime,
  site,
  unloadStartTimestamp,
}: BaseAppointment<T>): DateTime | null {
  switch (site.appointmentTimerStart) {
    case AppointmentStart.AppointmentTime:
      return scheduledArrivalTime;
    case AppointmentStart.GateIn:
      return gateInTimestamp;
    case AppointmentStart.OnComplex:
      return onComplexTimestamp;
    case AppointmentStart.UnloadStart:
      return unloadStartTimestamp;
    default:
      throwUnhandledCaseError(
        "appointment timer start",
        site.appointmentTimerStart,
      );
  }
}

const gateInStatuses = [
  AppointmentStatusCode.GateIn,
  AppointmentStatusCode.OnComplex,
];

const gateOutStatuses = [
  AppointmentStatusCode.GateOut,
  AppointmentStatusCode.GateOutDoorOccupied,
  AppointmentStatusCode.GateOutOffComplex,
];

const modifiableStatuses = [
  AppointmentStatusCode.Open,
  AppointmentStatusCode.OnComplex,
  AppointmentStatusCode.GateIn,
];

const movableStatuses = [
  AppointmentStatusCode.Open,
  AppointmentStatusCode.OnComplex,
];
