import { DateTime } from "luxon";
import { Site } from "src/app/core/sites";
import { AppointmentStatus } from "src/app/partner/appointments/appointment-status.model";
import { Partner } from "src/app/partner/global/partner.model";
import {
  createGlobalResourceUrl,
  createPartnerResourceUrl,
  getApiDetailsDecorator,
  getEnumMember,
  ODataModel,
  ODataResourceModel,
  parseDateTime,
  PartnerResourceModel,
  UUID,
} from "src/utils";
import { Attachment } from "../../attachment.model";
import {
  HelpAssistEventType,
  HelpAssistTicketStatus,
  HelpAssistTicketType,
} from "../../help-assist-enums";
import { HelpAssistEvent } from "../../help-assist-event.model";
import { HelpAssistUser } from "../../help-assist-user.model";
import { HelpAssistTicketDock } from "../help-assist-ticket-dock.model";
import { HelpAssistTicketResolution } from "../help-assist-ticket-resolution.model";
import {
  GlobalHelpAssistTicketAppointmentSchedule,
  PartnerHelpAssistTicketAppointmentSchedule,
} from "../ticket-schedule/help-assist-ticket-appointment-schedule.model";
import {
  BaseHelpAssistTicket,
  getHelpAssistTicketRouteUrl,
  HelpAssistTicketReference,
} from "./base-help-assist-ticket.model";

interface GlobalDeserializeArguments {
  readonly partners: readonly Partner[];
  readonly statuses: readonly AppointmentStatus[];
}

interface PartnerDeserializeArguments extends GlobalDeserializeArguments {
  readonly site: Site;
}

export const helpTicketsResourcePartner =
  createPartnerResourceUrl("HelpAssistTickets");

export const expandedHelpTicketsResourcePartner =
  helpTicketsResourcePartner.modify((resource) =>
    resource
      .addExpandField("ownerUser")
      .addExpandField("assignedUser")
      .addExpandField("resolutions")
      .addExpandField("appointmentSchedule", (scheduleResource) =>
        scheduleResource
          .addExpandField("appointment", (appointmentResource) =>
            appointmentResource
              .addExpandField("appointmentApproval")
              .addExpandField("carrier", (carrierResource) =>
                carrierResource.select("id", "name"),
              )
              .addExpandField("lastActivity")
              .addExpandField("vendor", (vendorResource) =>
                vendorResource.select("id", "name", "vendorNumber"),
              ),
          )
          .addExpandField("ticketAppointment", (ticketAppointmentResource) =>
            ticketAppointmentResource
              .addExpandField("orders", (ordersResource) =>
                ordersResource
                  .addExpandField("vendor", (vendorResource) =>
                    vendorResource.select(
                      "allowSameDayAppointment",
                      "appointmentNotificationEmails",
                      "customMinutesPerUnit",
                      "id",
                      "maxCalcMinutesPerUnit",
                      "maxLoadCount",
                      "minutesPerUnit",
                      "name",
                      "offerUnreservedSlots",
                      "vendorNumber",
                    ),
                  )
                  .addExpandField("doorGroup", (doorGroupResource) =>
                    doorGroupResource.select("id", "name"),
                  ),
              )
              .addExpandField("doorGroup", (doorGroupResource) =>
                doorGroupResource.select("id", "name"),
              )
              .addExpandField("door", (doorResource) =>
                doorResource.select("id", "name"),
              )
              .addExpandField("carrier", (carrierResource) =>
                carrierResource.select("id", "name"),
              )
              .addExpandField(
                "deliveryCarrierRecord",
                (deliveryCarrierResource) =>
                  deliveryCarrierResource.select("id", "name"),
              ),
          ),
      )
      .addExpandField("events", (eventsResource) =>
        eventsResource.addExpandField("user").orderBy("id", "desc"),
      )
      .addExpandField("attachments", (attachmentsResource) =>
        attachmentsResource
          .addExpandField("createdByUser")
          .addExpandField("deletedByUser")
          .orderBy("id", "desc"),
      )
      .addExpandField("dock", (dock) => dock.select("id", "name")),
  );

export const helpTicketsResourceGlobal =
  createGlobalResourceUrl("HelpAssistTickets");

export const expandedHelpTicketsResourceGlobal = helpTicketsResourceGlobal
  .addExpandField("site")
  .addExpandField("ownerUser")
  .addExpandField("assignedUser")
  .addExpandField("appointmentSchedule", (scheduleResource) =>
    scheduleResource
      .addExpandField("ticketAppointment", (ticketAppointment) =>
        ticketAppointment
          .addExpandField("orders", (ordersResource) =>
            ordersResource
              .select("id")
              .addExpandField("vendor", (vendorResource) =>
                vendorResource.select("id", "name", "vendorNumber"),
              ),
          )
          .addExpandField("carrier", (carrierResource) =>
            carrierResource.select("id", "name"),
          )
          .addExpandField("deliveryCarrierRecord", (deliveryCarrierResource) =>
            deliveryCarrierResource.select("id", "name"),
          ),
      )
      .addExpandField("appointment", (appointment) =>
        appointment
          .addExpandField("appointmentApproval")
          .addExpandField("carrier", (carrier) => carrier.select("id", "name"))
          .addExpandField("lastActivity")
          .addExpandField("vendor", (vendorResource) =>
            vendorResource.select("id", "name", "vendorNumber"),
          ),
      ),
  )
  .addExpandField("events", (eventsResource) =>
    eventsResource
      .addFilter("type", "!=", HelpAssistEventType.EmailSent)
      .addExpandField("user")
      .orderBy("id", "desc")
      .top(1),
  )
  .addExpandField("dock", (dock) => dock.select("id", "name"));

type GlobalApiModel = ODataModel<
  ODataResourceModel<typeof expandedHelpTicketsResourceGlobal>
>;
type PartnerApiModel = ODataModel<
  PartnerResourceModel<typeof expandedHelpTicketsResourcePartner>
>;

type ApiModel = GlobalApiModel | PartnerApiModel;

const api = getApiDetailsDecorator<ApiModel>();

type ComputedProperty = "hasNewComment" | "isResolved";

type ScopeDependentProperty = "attachments" | "resolutions";

abstract class SharedScopeHelpAssistTicket
  extends BaseHelpAssistTicket
  implements HelpAssistTicketReference
{
  protected constructor(
    args: Omit<ClassProperties<SharedScopeHelpAssistTicket>, ComputedProperty>,
  ) {
    super(args);
    this.assignedUser = args.assignedUser;
    this.category = args.category;
    this.createdOn = args.createdOn;
    this.dock = args.dock;
    this.events = args.events;
    this.id = args.id;
    this.isAutoApproved = args.isAutoApproved;
    this.isRead = args.isRead;
    this.lastEventCreatedOn = args.lastEventCreatedOn;
    this.orders = args.orders;
    this.organizationId = args.organizationId;
    this.organizationName = args.organizationName;
    this.ownerUser = args.ownerUser;
    this.status = args.status;
    this.updatedOn = args.updatedOn;
    this.version = args.version;

    // Computed Properties

    const commentEventsCount =
      this.events?.filter(
        (event) => event.type === HelpAssistEventType.Commented,
      ).length ?? 0;
    this.hasNewComment =
      !this.isRead &&
      commentEventsCount > 0 &&
      (this.status === HelpAssistTicketStatus.Open ||
        this.status === HelpAssistTicketStatus.InProgress ||
        this.status === HelpAssistTicketStatus.AwaitingReply);

    this.isResolved =
      this.status === HelpAssistTicketStatus.Closed ||
      this.status === HelpAssistTicketStatus.Expired;
  }

  @api({ key: "assignedUser", uiModel: HelpAssistUser })
  public readonly assignedUser: HelpAssistUser | null;
  @api() public readonly category: string | null;
  @api() public readonly createdOn: DateTime;
  @api({
    key: "dockId",
    uiModel: HelpAssistTicketDock,
    navigationProperty: "dock",
  })
  public readonly dock: HelpAssistTicketDock | null;
  @api() public readonly events: readonly HelpAssistEvent[];
  @api() public readonly id: HelpAssistTicketReference["id"];
  @api() public readonly isAutoApproved: boolean;
  @api() public readonly isRead: boolean;
  @api() public readonly lastEventCreatedOn: DateTime;
  @api() public readonly orders: string | null;
  @api() public readonly organizationId: string | null;
  @api() public readonly organizationName: string | null;
  @api({
    key: "ownerUserId",
    navigationProperty: "ownerUser",
    uiModel: HelpAssistUser,
  })
  public readonly ownerUser: HelpAssistUser;
  @api() public readonly status: HelpAssistTicketStatus;
  @api() public readonly updatedOn: DateTime;
  public readonly version: string;

  public readonly hasNewComment: boolean;
  public readonly isResolved: boolean;

  protected static deserializeBase(
    apiModel: ApiModel,
    { site }: { readonly site: Site },
  ): Omit<
    ClassProperties<SharedScopeHelpAssistTicket>,
    ComputedProperty | ScopeDependentProperty
  > {
    const ownerUser = HelpAssistUser.deserialize(apiModel.ownerUser);

    return {
      assignedUser: apiModel.assignedUser
        ? HelpAssistUser.deserialize(apiModel.assignedUser)
        : null,
      site,
      category: apiModel.category ?? null,
      createdOn: parseDateTime(apiModel.createdOn, site),
      dock: apiModel.dock
        ? HelpAssistTicketDock.deserialize(apiModel.dock)
        : null,
      emailAddress: apiModel.emailAddress,
      events: HelpAssistEvent.deserializeList(
        // TODO: Inspect why there's a TS error here related to
        //  DoorGroup type even though it's not expanded here.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        { value: apiModel.events },
        { ownerUser },
      ),
      id: apiModel.id,
      isAutoApproved: apiModel.isAutoApproved,
      isRead: apiModel.isRead,
      lastEventCreatedOn: parseDateTime(apiModel.lastEventCreatedOn, site),
      orders: apiModel.orders ?? null,
      organizationId: apiModel.organizationId ?? null,
      organizationName: apiModel.organizationName ?? null,
      ownerUser,
      phoneNumber: apiModel.phoneNumber ?? null,
      status: getEnumMember(HelpAssistTicketStatus, apiModel.status),
      type: getEnumMember(HelpAssistTicketType, apiModel.type),
      updatedOn: parseDateTime(apiModel.updatedOn, site),
      version: apiModel["@odata.etag"],
    };
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getHelpAssistTicketRouteUrl(this, ...childPaths);
  }
}

export class GlobalHelpAssistTicket extends SharedScopeHelpAssistTicket {
  private constructor(
    args: Omit<ClassProperties<GlobalHelpAssistTicket>, ComputedProperty>,
  ) {
    super(args);
    this.ticketAppointmentSchedule = args.ticketAppointmentSchedule;
  }

  @api({
    key: "appointmentScheduleId",
    navigationProperty: "appointmentSchedule",
    uiModel: GlobalHelpAssistTicketAppointmentSchedule,
  })
  public readonly ticketAppointmentSchedule: GlobalHelpAssistTicketAppointmentSchedule | null;

  public static deserialize(
    apiModel: GlobalApiModel,
    { partners, statuses }: GlobalDeserializeArguments,
  ): GlobalHelpAssistTicket {
    const partnerKey = new UUID(apiModel.partnerKey);
    const partner = partners.find((p) => p.key.isSame(partnerKey));
    if (!partner) {
      throw new Error(
        `Could not find partner with Key "${partnerKey.toString()}"`,
      );
    }

    const site = Site.deserialize(apiModel.site, { partner });

    const ticketAppointmentSchedule = apiModel.appointmentSchedule
      ? GlobalHelpAssistTicketAppointmentSchedule.deserialize(
          apiModel.appointmentSchedule,
          { site, statuses },
        )
      : null;

    return new GlobalHelpAssistTicket({
      ...this.deserializeBase(apiModel, { site }),
      ticketAppointmentSchedule,
    });
  }

  public static deserializeList(
    { value }: { readonly value: readonly GlobalApiModel[] },
    args: GlobalDeserializeArguments,
  ): readonly GlobalHelpAssistTicket[] {
    return value.map((ticket) =>
      GlobalHelpAssistTicket.deserialize(ticket, args),
    );
  }
}

export class PartnerHelpAssistTicket extends SharedScopeHelpAssistTicket {
  private constructor(
    args: Omit<ClassProperties<PartnerHelpAssistTicket>, ComputedProperty>,
  ) {
    super(args);
    this.attachments = args.attachments;
    this.resolutions = args.resolutions;
    this.ticketAppointmentSchedule = args.ticketAppointmentSchedule;
  }

  public readonly attachments: readonly Attachment[];
  public readonly resolutions: readonly HelpAssistTicketResolution[] | null;
  @api({
    key: "appointmentScheduleId",
    uiModel: PartnerHelpAssistTicketAppointmentSchedule,
    navigationProperty: "appointmentSchedule",
  })
  public readonly ticketAppointmentSchedule: PartnerHelpAssistTicketAppointmentSchedule | null;

  public static deserialize(
    apiModel: PartnerApiModel,
    { site, statuses }: PartnerDeserializeArguments,
  ): PartnerHelpAssistTicket {
    return new PartnerHelpAssistTicket({
      ...this.deserializeBase(apiModel, { site }),
      attachments: Attachment.deserializeList(apiModel.attachments, { site }),
      resolutions: apiModel.resolutions
        ? HelpAssistTicketResolution.deserializeList(apiModel.resolutions)
        : null,
      ticketAppointmentSchedule: apiModel.appointmentSchedule
        ? PartnerHelpAssistTicketAppointmentSchedule.deserialize(
            apiModel.appointmentSchedule,
            { site, statuses },
          )
        : null,
    });
  }
}

export type HelpAssistTicket = GlobalHelpAssistTicket | PartnerHelpAssistTicket;
