import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Duration } from "luxon";
import { DropLoadAvailability, ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { ODataReference } from "src/app/core/odata-reference.model";
import { Site } from "src/app/core/sites";
import { TimeOfDay } from "src/app/core/time-of-day.model";
import { Weekdays } from "src/app/core/weekdays.model";
import { Carrier, SimpleCarrier } from "src/app/partner/global/carriers";
import { getVendorRouteUrl } from "src/app/partner/vendors/base-vendor.model";
import {
  deserializeVendorDisplayName,
  Vendor,
} from "src/app/partner/vendors/vendor.model";
import { getApiDetailsDecorator } from "src/utils";

const api = getApiDetailsDecorator<Api.Reservation>();

export abstract class BaseReservation {
  protected constructor(args: ClassProperties<BaseReservation>) {
    this.carriers = args.carriers;
    this.dropLoadAvailability = args.dropLoadAvailability;
    this.duration = args.duration;
    this.endDay = args.endDay;
    this.exceptionDays = args.exceptionDays;
    this.isActive = args.isActive;
    this.managedType = args.managedType;
    this.maximumCaseCount = args.maximumCaseCount;
    this.maximumPalletCount = args.maximumPalletCount;
    this.minimumCaseCount = args.minimumCaseCount;
    this.minimumPalletCount = args.minimumPalletCount;
    this.name = args.name;
    this.site = args.site;
    this.startDay = args.startDay;
    this.startTime = args.startTime;
    this.vendors = args.vendors;
    this.weekdays = args.weekdays;
  }

  public readonly carriers: readonly ReservationCarrier[];
  @api({ key: "dropLoad" })
  public readonly dropLoadAvailability: DropLoadAvailability;
  @api({ key: "length" }) public readonly duration: Duration;
  @api({ key: "effectiveEndDate" }) public readonly endDay: Day;
  @api({ key: "exceptions" }) public readonly exceptionDays: Day[];
  @api({ key: "active" }) public readonly isActive: boolean;
  @api() public readonly managedType: ManagedType | null;
  @api({ key: "maxCases" }) public readonly maximumCaseCount: number | null;
  @api({ key: "maxPallets" }) public readonly maximumPalletCount: number | null;
  @api({ key: "minCases" }) public readonly minimumCaseCount: number | null;
  @api({ key: "minPallets" }) public readonly minimumPalletCount: number | null;
  @api() public readonly name: string | null;
  @api({ key: "siteID", navigationProperty: "site", uiModel: Site })
  public readonly site: Site;
  @api({ key: "effectiveStartDate" }) public readonly startDay: Day;
  @api() public readonly startTime: TimeOfDay;
  public readonly vendors: readonly ReservationVendor[];
  @api({ key: "dayOfWeek" }) public readonly weekdays: Weekdays;
}

export function deserializeReservationVendor(
  data: Pick<Api.Vendor, "doorCount" | "id" | "name" | "vendorNumber"> &
    Api.ODataReference,
  { site }: { readonly site: Site },
): ReservationVendor {
  return {
    ...deserializeVendorDisplayName({
      name: data.name,
      vendorNumber: data.vendorNumber,
    }),
    getRouteUrl: (...childPaths) =>
      getVendorRouteUrl({ id: data.id, site }, ...childPaths),
    id: data.id,
    maxAppointmentDoorCount: data.doorCount,
    name: data.name,
    number: data.vendorNumber,
    reference: new ODataReference(data["@odata.id"]),
  };
}

export interface ReservationVendor
  extends Pick<
    Vendor,
    | "displayName"
    | "id"
    | "maxAppointmentDoorCount"
    | "name"
    | "number"
    | "reference"
  > {
  getRouteUrl(...childPaths: string[]): string;
}

export interface ReservationCarrier
  extends SimpleCarrier,
    Pick<Carrier, "reference"> {}
