import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { isExistent } from "src/utils";
import { AppointmentChangesValue } from "../models/appointment-changes-value.model";
import {
  TicketAppointmentDetailsEntryDirective,
  TicketAppointmentDetailsEntryDirectiveContext,
} from "./ticket-appointment-details-entry.directive";

@Component({
  selector: "mr-appointment-details-entry",
  templateUrl: "./ticket-appointment-details-entry.component.html",
  styleUrls: ["./ticket-appointment-details-entry.component.scss"],
})
export class TicketAppointmentDetailsEntryComponent<T> {
  @Input() public currentValue!: T;
  @Input() public change?: AppointmentChangesValue<T> | null;

  public isExistent = isExistent;

  @ContentChild(TicketAppointmentDetailsEntryDirective, { read: TemplateRef })
  public readonly valueTemplate?: TemplateRef<
    TicketAppointmentDetailsEntryDirectiveContext<T>
  >;
}
