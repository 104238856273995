import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import { isExistent } from "src/utils";
import { HelpAssistTicketAppointmentDraftUpdate } from "./ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";

export class HelpAssistHasScheduledTicketForRequest {
  public constructor(
    args: ClassProperties<HelpAssistHasScheduledTicketForRequest>,
  ) {
    this.appointmentDraft = args.appointmentDraft;
    this.appointmentId = args.appointmentId;
    this.site = args.site;
  }

  public readonly appointmentDraft: HelpAssistTicketAppointmentDraftUpdate | null;
  public readonly appointmentId: AppointmentReference["id"] | null;
  public readonly site: Site;

  public serialize(): Api.HelpAssistHasScheduledTicketForRequest {
    return {
      appointmentId: this.appointmentId ?? null,
      carrierId: this.appointmentDraft?.carrier?.id ?? null,
      orders:
        this.appointmentDraft?.orders
          ?.map(
            ({ asnBolNumber, asnProNumber, bolNumber, number, proNumber }) => ({
              asnBolNumber,
              asnProNumber,
              bolNumber,
              number,
              proNumber,
            }),
          )
          .filter(isExistent) ?? [],
      siteId: this.site.id,
    };
  }
}
