import { Component } from "@angular/core";
import { Duration } from "luxon";
import { combineLatest, firstValueFrom, timer } from "rxjs";
import { map } from "rxjs/operators";
import { Day } from "src/app/core/day.model";
import { BaseFieldComponent } from "src/app/core/forms/base-field.component";
import { SitesService } from "src/app/core/sites";

@Component({
  selector: "mr-date-stepper",
  template: `
    <div>
      <mr-icon-button
        class="prev-day-button"
        label="Previous Day"
        (click)="setToPreviousDay()"
      >
        <mr-icon class="left-chevron" name="chevron"></mr-icon>
      </mr-icon-button>

      <mr-date-picker
        [ngModel]="value"
        label="Schedule Date"
        labelHidden
        (ngModelChange)="onChange($event)"
        [disableClear]="true"
      ></mr-date-picker>

      <mr-icon-button
        class="next-day-button"
        label="Next Day"
        (click)="setToNextDay()"
      >
        <mr-icon class="right-chevron" name="chevron"></mr-icon>
      </mr-icon-button>
    </div>

    <mr-button
      kind="secondary"
      (click)="setToToday()"
      [disabled]="isTodayChanges | async"
      >Show Today</mr-button
    >
  `,
  styleUrls: ["./date-stepper.component.scss"],
})
export class DateStepperComponent extends BaseFieldComponent<Day> {
  public constructor(private readonly sites: SitesService) {
    super();
  }

  public readonly isTodayChanges = combineLatest([
    this.sites.selectedChanges,
    this.valueChanges,
    timer(0, currentTimeRefreshInterval.toMillis()),
  ]).pipe(map(([{ timeZone }, value]) => !!value && value.isTodayIn(timeZone)));

  public async setToPreviousDay(): Promise<void> {
    const { timeZone } = await firstValueFrom(this.sites.selectedChanges);
    this.onChange((this.value ?? Day.getTodayIn(timeZone)).minus({ days: 1 }));
  }

  public async setToNextDay(): Promise<void> {
    const { timeZone } = await firstValueFrom(this.sites.selectedChanges);
    this.onChange((this.value ?? Day.getTodayIn(timeZone)).plus({ days: 1 }));
  }

  public async setToToday(): Promise<void> {
    const { timeZone } = await firstValueFrom(this.sites.selectedChanges);
    this.onChange(Day.getTodayIn(timeZone));
  }
}

export const currentTimeRefreshInterval = Duration.fromObject({ seconds: 10 });
