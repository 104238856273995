import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { AppointmentReference } from "src/app/partner/appointments/base-appointment.model";
import { HelpAssistTicketAppointmentDraftUpdate } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { getUpdatedPropertiesFilter } from "src/utils";

export type HelpAssistTicketAppointmentScheduleUpdateArgs =
  ClassProperties<HelpAssistTicketAppointmentScheduleUpdate>;

export class HelpAssistTicketAppointmentScheduleUpdate {
  public constructor({
    ...args
  }: HelpAssistTicketAppointmentScheduleUpdateArgs) {
    this.ticketAppointment = args.ticketAppointment;
    this.appointmentId = args.appointmentId;
    this.site = args.site;

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, null, args);
  }

  public readonly ticketAppointment: HelpAssistTicketAppointmentDraftUpdate | null;
  public readonly appointmentId: AppointmentReference["id"] | null;
  public readonly site: Site;

  public readonly filterUpdatedProperties: FilterPartial<Api.HelpAssistTicketAppointmentScheduleUpdate>;

  public serialize(): Partial<
    Api.HelpAssistTicketAppointmentScheduleUpdate & {
      ticketAppointment: Partial<Api.HelpAssistTicketAppointmentDraftUpdate> | null;
    }
  > {
    const filterOptions = this.filterUpdatedProperties({
      appointmentId: this.appointmentId,
    });

    return {
      ...filterOptions,
      ticketAppointment: this.ticketAppointment?.serialize() ?? null,
    };
  }
}
