import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Chain } from "fp-ts/es6/Either";
import * as t from "io-ts";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { Site } from "src/app/core/sites";
import { PointOfOrigin } from "src/app/partner/purchase-orders/base-purchase-order.model";
import { Vendor } from "src/app/partner/vendors/vendor.model";
import { getEnumMember, isInstanceOf } from "src/utils";
import { AppointmentChangesValue } from "./appointment-changes-value.model";
import { HelpAssistTicketAppointmentOrder } from "./ticket-order/help-assist-ticket-appointment-order.model";

interface DeserializeArgs {
  site: Site;
}

export type AppointmentOrder = Omit<
  ClassProperties<HelpAssistTicketAppointmentOrder>,
  "helpAssistTicketAppointmentId" | "id" | "partnerKey" | "site" | "vendor"
> & {
  vendorName: Vendor["name"] | null;
  vendorNumber: Vendor["number"] | null;
};

export type AppointmentOrderUpdates = {
  [P in keyof Omit<AppointmentOrder, "orderId">]: AppointmentChangesValue<
    AppointmentOrder[P] | null
  >;
};
export class AppointmentOrderChanges {
  private constructor(args: ClassProperties<AppointmentOrderChanges>) {
    this.addedOrders = args.addedOrders;
    this.updatedOrders = args.updatedOrders;
    this.removedOrderIds = args.removedOrderIds;
  }

  public readonly addedOrders: Map<number, AppointmentOrder>;
  public readonly updatedOrders: Map<number, AppointmentOrderUpdates>;
  public readonly removedOrderIds: number[];

  public static deserialize(
    apiModel: readonly Api.AppointmentOrderChanges[],
    args: DeserializeArgs,
  ): AppointmentOrderChanges {
    const addedOrders = new Map<number, AppointmentOrder>();
    const updatedOrders = new Map<number, AppointmentOrderUpdates>();
    const removedOrderIds: number[] = [];

    for (const a of apiModel) {
      switch (a.changeType) {
        case "Added":
          addedOrders.set(a.orderId, getAddedOrder(a, args));
          break;
        case "Updated":
          updatedOrders.set(a.orderId, getUpdatedOrder(a, args));
          break;
        case "Removed":
          removedOrderIds.push(a.orderId);
          break;
      }
    }

    return new AppointmentOrderChanges({
      addedOrders,
      removedOrderIds,
      updatedOrders,
    });
  }
}

function getAddedOrder(
  { changes, orderId }: Api.AppointmentOrderChanges,
  { site }: DeserializeArgs,
): AppointmentOrder {
  if (!changesCodec.is(changes)) {
    throw new Error("Unexpected changes from added order.");
  }

  return {
    asnBolNumber: changes.asnBolNumber ?? null,
    asnProNumber: changes.asnProNumber ?? null,
    backhaulPickupConfirmationNumber:
      changes.backhaulPickupConfirmationNumber ?? null,
    bolNumber: changes.bolNumber ?? null,
    caseCount: changes.caseCount,
    comments: changes.comments ?? null,
    consigneeCode: changes.consigneeCode ?? null,
    doorGroup: null,
    dueDate: changes.dueDate
      ? Day.deserialize(changes.dueDate, site.timeZone)
      : null,
    entryDate: changes.entryDate
      ? Day.deserialize(changes.entryDate, site.timeZone)
      : null,
    inboundPalletCount: changes.estimatedReceivedPalletCount,
    loadWeight: changes.loadWeight ?? null,
    managedType: getEnumMember(ManagedType, changes.managedType, null),
    number: changes.number,
    orderId,
    pickupDate: changes.pickupDate
      ? Day.deserialize(changes.pickupDate, site.timeZone)
      : null,
    pointOfOrigin: changes.pointOfOrigin ?? null,
    proNumber: changes.proNumber ?? null,
    vendorId: changes.vendorId,
    vendorName: changes.vendor,
    vendorNumber: changes.vendorNumber,
    warehousePalletCount: changes.palletCount,
  };
}
function getUpdatedOrder(
  { changes }: Api.AppointmentOrderChanges,
  { site }: DeserializeArgs,
): AppointmentOrderUpdates {
  if (!partialChangesCodec.is(changes)) {
    throw new Error("Unexpected changes from updated order.");
  }

  return {
    asnBolNumber: new AppointmentChangesValue({
      isChanged: "asnBolNumber" in changes,
      value: changes.asnBolNumber ?? null,
    }),
    asnProNumber: new AppointmentChangesValue({
      isChanged: "asnProNumber" in changes,
      value: changes.asnProNumber ?? null,
    }),
    backhaulPickupConfirmationNumber: new AppointmentChangesValue({
      isChanged: "backhaulPickupConfirmationNumber" in changes,
      value: changes.backhaulPickupConfirmationNumber ?? null,
    }),
    bolNumber: new AppointmentChangesValue({
      isChanged: "bolNumber" in changes,
      value: changes.bolNumber ?? null,
    }),
    caseCount: new AppointmentChangesValue({
      isChanged: "caseCount" in changes,
      value: changes.caseCount ?? null,
    }),
    comments: new AppointmentChangesValue({
      isChanged: "comments" in changes,
      value: changes.comments ?? null,
    }),
    consigneeCode: new AppointmentChangesValue({
      isChanged: "consigneeCode" in changes,
      value: changes.consigneeCode ?? null,
    }),
    doorGroup: new AppointmentChangesValue({
      isChanged: "doorGroup" in changes,
      value: null,
    }),
    dueDate: new AppointmentChangesValue({
      isChanged: "dueDate" in changes,
      value: changes.dueDate
        ? Day.deserialize(changes.dueDate, site.timeZone)
        : null,
    }),
    entryDate: new AppointmentChangesValue({
      isChanged: "entryDate" in changes,
      value: changes.entryDate
        ? Day.deserialize(changes.entryDate, site.timeZone)
        : null,
    }),
    inboundPalletCount: new AppointmentChangesValue({
      isChanged: "estimatedReceivedPalletCount" in changes,
      value: changes.estimatedReceivedPalletCount ?? null,
    }),
    loadWeight: new AppointmentChangesValue({
      isChanged: "loadWeight" in changes,
      value: changes.loadWeight ?? null,
    }),
    managedType: new AppointmentChangesValue({
      isChanged: "managedType" in changes,
      value: getEnumMember(ManagedType, changes.managedType, null),
    }),
    number: new AppointmentChangesValue({
      isChanged: "number" in changes,
      value: changes.number ?? null,
    }),
    pickupDate: new AppointmentChangesValue({
      isChanged: "pickupDate" in changes,
      value: changes.pickupDate
        ? Day.deserialize(changes.pickupDate, site.timeZone)
        : null,
    }),
    pointOfOrigin: new AppointmentChangesValue({
      isChanged: "pointOfOrigin" in changes,
      value: changes.pointOfOrigin ?? null,
    }),
    proNumber: new AppointmentChangesValue({
      isChanged: "proNumber" in changes,
      value: changes.proNumber ?? null,
    }),
    vendorId: new AppointmentChangesValue({
      isChanged: "vendorId" in changes,
      value: changes.vendorId ?? null,
    }),
    vendorName: new AppointmentChangesValue({
      isChanged: "vendor" in changes,
      value: changes.vendor ?? null,
    }),
    vendorNumber: new AppointmentChangesValue({
      isChanged: "vendorNumber" in changes,
      value: changes.vendorNumber ?? null,
    }),
    warehousePalletCount: new AppointmentChangesValue({
      isChanged: "palletCount" in changes,
      value: changes.palletCount ?? null,
    }),
  };
}
const pointOfOriginCodec = new t.Type<PointOfOrigin, PointOfOrigin, unknown>(
  "pointOfOrigin",
  isInstanceOf(PointOfOrigin),
  (value, context) => {
    return Chain.chain(
      t
        .type({
          originCity: t.string,
          originLatitude: t.number,
          originLongitude: t.number,
          originPostalCode: t.string,
          originState: t.string,
        })
        .validate(value, context),
      (validatedValue) => {
        try {
          return t.success(PointOfOrigin.deserialize(validatedValue));
        } catch {
          return t.failure(value, context);
        }
      },
    );
  },
  (value) => value || null,
);

// None of this is guaranteed by the API but previous assertions were assuming
// it so we'll continue to validate it here. Nullable/optional properties are
// possibly excluded rather than being returned as `null` when unset.
const changesRequiredPropertiesCodec = t.type({
  caseCount: t.number,
  estimatedReceivedPalletCount: t.number,
  number: t.string,
  palletCount: t.number,
  vendor: t.string,
  vendorId: t.number,
  vendorNumber: t.string,
});
const changesOptionalPropertiesCodec = t.partial({
  asnBolNumber: t.union([t.string, t.null]),
  asnProNumber: t.union([t.string, t.null]),
  backhaulPickupConfirmationNumber: t.union([t.string, t.null]),
  bolNumber: t.union([t.string, t.null]),
  comments: t.union([t.string, t.null]),
  consigneeCode: t.union([t.string, t.null]),
  dueDate: t.union([t.string, t.null]),
  entryDate: t.union([t.string, t.null]),
  loadWeight: t.union([t.number, t.null]),
  managedType: t.union([t.string, t.null]),
  pickupDate: t.union([t.string, t.null]),
  pointOfOrigin: t.union([pointOfOriginCodec, t.null]),
  proNumber: t.union([t.string, t.null]),
});

const changesCodec = t.intersection([
  changesRequiredPropertiesCodec,
  changesOptionalPropertiesCodec,
]);

const partialChangesCodec = t.intersection([
  t.partial(changesRequiredPropertiesCodec.props),
  changesOptionalPropertiesCodec,
]);
