import { Environment } from "./environment-type";
import { getBuildIdentifier } from "./get-build-identifier";
import { getConfig } from "./get-global-config";

// This file will be create by `scripts/version.ts` as part of the build process.
// It can be run manually for testing but DO NOT check in the generated file.
import versionInfo from "./version-info.json";

const {
  apiUrls,
  applicationInsights,
  azureAD,
  environment: environmentType,
  fileExchangeAPIManagementKey,
  googleMapsApiKey,
  powerBI,
  ymsUrls,
} = getConfig();

export const environment: Environment = {
  apiUrls,
  applicationInsights,
  azureAD,
  build: getBuildIdentifier({ ...versionInfo, environment: environmentType }),
  fileExchangeAPIManagementKey,
  googleMapsApiKey,
  isProductionBuild: true,
  powerBI,
  type: environmentType,
  version: versionInfo.version,
  ymsUrls,
};
