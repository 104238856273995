import { Permission } from "./permissions";

export function mapClaimsToPermissions(
  claims: readonly string[],
): ReadonlySet<Permission> {
  const permissions = [];
  for (const claim of claims) {
    const claimPermissions =
      claimPermissionsLookup[claim.trim().toLowerCase()] ?? [];
    permissions.push(...claimPermissions);
  }
  return new Set(permissions);
}

const claimPermissionsLookup: {
  readonly [claim: string]: readonly Permission[] | undefined;
} =
  /* 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
   *                  This should not be adjusted manually!
   * 🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨
   *
   * Please see "Importing the Sheet" in the `/docs/permissions.md`
   * documentation for details.
   */
  // Don't move/remove this comment (used for automated replacement): ✂️
  {
    admin: [
      Permission.CreateActivity,
      Permission.CreateAdminSite,
      Permission.CreateAppointmentAdvanced,
      Permission.CreateAppointmentAdvancedPropertyUnloaderOptionPartner,
      Permission.CreateAppointmentBackdated,
      Permission.CreateAutoAppointQuota,
      Permission.CreateAutoAppointRule,
      Permission.CreateBuyer,
      Permission.CreateClosure,
      Permission.CreateClosureGameDay,
      Permission.CreateDock,
      Permission.CreateDoor,
      Permission.CreateDoorGroup,
      Permission.CreateEquipment,
      Permission.CreateGlobalCarrier,
      Permission.CreateHelpAssistTicket,
      Permission.CreateMoveRequest,
      Permission.CreatePartnerCarrier,
      Permission.CreatePurchaseOrder,
      Permission.CreatePurchaseOrderBackdated,
      Permission.CreateRack,
      Permission.CreateRackException,
      Permission.CreateReservation,
      Permission.DeleteAutoAppointQuota,
      Permission.DeleteAutoAppointRule,
      Permission.DeleteBuyer,
      Permission.DeleteClosure,
      Permission.DeleteEquipment,
      Permission.EditActivity,
      Permission.EditAdminSite,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentBypassApproval,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationPropertyUnloaderOptionPartner,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditAutoAppointQuota,
      Permission.EditAutoAppointRule,
      Permission.EditBuyer,
      Permission.EditClosure,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditEmailTemplate,
      Permission.EditEquipment,
      Permission.EditGlobalCarrier,
      Permission.EditHelpAssistTicket,
      Permission.EditHelpAssistTicketAdvanced,
      Permission.EditHelpAssistTicketAdvancedApprovalOverride,
      Permission.EditPartner,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditSite,
      Permission.EditSitePropertyInformation,
      Permission.EditVendor,
      Permission.ViewActivityList,
      Permission.ViewAdmin,
      Permission.ViewAdminSiteList,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEmailTemplateList,
      Permission.ViewEquipmentList,
      Permission.ViewEquipmentTypeList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskAdminDetails,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPartnerList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSite,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    partneradmin: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateAppointmentAdvancedPropertyUnloaderOptionPartner,
      Permission.CreateAppointmentBackdated,
      Permission.CreateAutoAppointQuota,
      Permission.CreateAutoAppointRule,
      Permission.CreateBuyer,
      Permission.CreateClosure,
      Permission.CreateClosureGameDay,
      Permission.CreateDock,
      Permission.CreateDoor,
      Permission.CreateDoorGroup,
      Permission.CreateEquipment,
      Permission.CreateHelpAssistTicket,
      Permission.CreateMoveRequest,
      Permission.CreatePartnerCarrier,
      Permission.CreatePurchaseOrder,
      Permission.CreatePurchaseOrderBackdated,
      Permission.CreateRack,
      Permission.CreateRackException,
      Permission.CreateReservation,
      Permission.DeleteAutoAppointQuota,
      Permission.DeleteAutoAppointRule,
      Permission.DeleteBuyer,
      Permission.DeleteClosure,
      Permission.DeleteEquipment,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentBypassApproval,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationPropertyUnloaderOptionPartner,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditAutoAppointQuota,
      Permission.EditAutoAppointRule,
      Permission.EditBuyer,
      Permission.EditClosure,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditEquipment,
      Permission.EditHelpAssistTicket,
      Permission.EditHelpAssistTicketAdvanced,
      Permission.EditHelpAssistTicketAdvancedApprovalOverride,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditSitePropertyInformation,
      Permission.EditVendor,
      Permission.ViewActivityList,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewEquipmentTypeList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskAdminDetails,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSite,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    helpdesk: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateClosureGameDay,
      Permission.CreateHelpAssistTicket,
      Permission.CreatePurchaseOrder,
      Permission.DeleteEquipment,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentBypassApproval,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditEquipment,
      Permission.EditHelpAssistTicket,
      Permission.EditHelpAssistTicketAdvanced,
      Permission.EditHelpAssistTicketAdvancedApprovalOverride,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditSitePropertyInformation,
      Permission.EditVendor,
      Permission.ViewActivityList,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewEquipmentTypeList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskAdminDetails,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSite,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    helpdeskplus: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateClosureGameDay,
      Permission.CreateHelpAssistTicket,
      Permission.CreateMoveRequest,
      Permission.CreatePurchaseOrder,
      Permission.DeleteEquipment,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentBypassApproval,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditEquipment,
      Permission.EditHelpAssistTicket,
      Permission.EditHelpAssistTicketAdvanced,
      Permission.EditHelpAssistTicketAdvancedApprovalOverride,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditSitePropertyInformation,
      Permission.EditVendor,
      Permission.ViewActivityList,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewEquipmentTypeList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskAdminDetails,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSite,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    opsleader: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateClosure,
      Permission.CreateClosureGameDay,
      Permission.CreateDock,
      Permission.CreateDoor,
      Permission.CreateDoorGroup,
      Permission.CreateHelpAssistTicket,
      Permission.CreatePartnerCarrier,
      Permission.CreatePurchaseOrder,
      Permission.CreateRack,
      Permission.CreateRackException,
      Permission.CreateReservation,
      Permission.DeleteClosure,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditClosure,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditVendor,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    opsleaderplus: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateClosure,
      Permission.CreateClosureGameDay,
      Permission.CreateDock,
      Permission.CreateDoor,
      Permission.CreateDoorGroup,
      Permission.CreateHelpAssistTicket,
      Permission.CreateMoveRequest,
      Permission.CreatePartnerCarrier,
      Permission.CreatePurchaseOrder,
      Permission.CreateRack,
      Permission.CreateRackException,
      Permission.CreateReservation,
      Permission.DeleteClosure,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditClosure,
      Permission.EditClosureGameDay,
      Permission.EditDock,
      Permission.EditDoor,
      Permission.EditDoorGroup,
      Permission.EditPartnerCarrier,
      Permission.EditRack,
      Permission.EditReservation,
      Permission.EditSchedule,
      Permission.EditVendor,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    opsworker: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateHelpAssistTicket,
      Permission.CreatePurchaseOrder,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewRackList,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    guard: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateHelpAssistTicket,
      Permission.CreatePurchaseOrder,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    guardplus: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateHelpAssistTicket,
      Permission.CreateMoveRequest,
      Permission.CreatePurchaseOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewMoveRequest,
      Permission.ViewMoveRequestList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    executive: [
      Permission.CreateHelpAssistTicket,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    buyer: [
      Permission.CreateHelpAssistTicket,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewGlobalCarrierList,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
    vendor: [],
    carrier: [
      Permission.CreateAppointmentGuided,
      Permission.CreateHelpAssistTicket,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentSlotGuided,
      Permission.EditHelpAssistTicket,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentList,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderList,
      Permission.ViewReservationListCarrier,
      Permission.ViewSitePerformance,
    ],
    logisticsworker: [
      Permission.CreateAppointmentAdvanced,
      Permission.CreateAppointmentAdvancedPropertyUnloaderOptionPartner,
      Permission.CreateHelpAssistTicket,
      Permission.EditAppointmentAddException,
      Permission.EditAppointmentBypassApproval,
      Permission.EditAppointmentCancel,
      Permission.EditAppointmentCancelUnrestricted,
      Permission.EditAppointmentCompletedRemoveOrder,
      Permission.EditAppointmentGateManagement,
      Permission.EditAppointmentInformation,
      Permission.EditAppointmentInformationPropertyUnloaderOptionPartner,
      Permission.EditAppointmentInformationUnrestricted,
      Permission.EditAppointmentPropertyStatus,
      Permission.EditAppointmentSlotAdvanced,
      Permission.EditHelpAssistTicket,
      Permission.EditHelpAssistTicketAdvanced,
      Permission.ViewAppointmentDetails,
      Permission.ViewAppointmentDetailsAdvanced,
      Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors,
      Permission.ViewAppointmentHistory,
      Permission.ViewAppointmentList,
      Permission.ViewAppointmentListFilterTabs,
      Permission.ViewAutoAppointList,
      Permission.ViewBuyerList,
      Permission.ViewClosureList,
      Permission.ViewDashboard,
      Permission.ViewDockList,
      Permission.ViewDoorGroupList,
      Permission.ViewDoorList,
      Permission.ViewEquipmentList,
      Permission.ViewEquipmentTypeList,
      Permission.ViewGameDayClosureList,
      Permission.ViewGameDaySchedule,
      Permission.ViewGlobalCarrierList,
      Permission.ViewGlobalSearch,
      Permission.ViewHelpAssistTicketListTabAllOpenTickets,
      Permission.ViewHelpDeskAdminDetails,
      Permission.ViewHelpDeskTicketDetails,
      Permission.ViewHelpDeskTicketList,
      Permission.ViewNotifications,
      Permission.ViewPartnerCarrierList,
      Permission.ViewPurchaseOrderDetails,
      Permission.ViewPurchaseOrderHistory,
      Permission.ViewPurchaseOrderList,
      Permission.ViewPurchaseOrderListFilterTabs,
      Permission.ViewReports,
      Permission.ViewReservationListAll,
      Permission.ViewReservationListCarrier,
      Permission.ViewSchedule,
      Permission.ViewSite,
      Permission.ViewSitePerformance,
      Permission.ViewVendorList,
    ],
  };
// 🚨 End of File (don't add anything after this).
