import { Component, Input } from "@angular/core";
import { Permission } from "src/app/core/auth";
import { SitesService } from "src/app/core/sites";
import { HelpAssistTicketAppointmentDraftUpdate } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft-update.model";
import { HelpAssistTicketAppointmentDraft } from "src/app/partner/help-assist/models/ticket-appointment-draft/help-assist-ticket-appointment-draft.model";
import { PartnerHelpAssistTicketAppointmentSchedule } from "src/app/partner/help-assist/models/ticket-schedule/help-assist-ticket-appointment-schedule.model";

@Component({
  selector: "mr-ticket-appointment-details",
  templateUrl: "./ticket-appointment-details.component.html",
  styleUrls: ["./ticket-appointment-details.component.scss"],
})
export class TicketAppointmentDetailsComponent {
  public constructor(private readonly sites: SitesService) {}

  @Input() public alignTitle?: string;
  @Input() public appointment!:
    | HelpAssistTicketAppointmentDraftUpdate
    | HelpAssistTicketAppointmentDraft;
  @Input()
  public appointmentSchedule?: PartnerHelpAssistTicketAppointmentSchedule;

  public readonly Permission = Permission;
  public readonly selectedSiteChanges = this.sites.selectedChanges;
}
