import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { Dock } from "src/app/partner/settings/docks/dock.model";
import { DoorGroup } from "src/app/partner/settings/door-groups/door-group.model";
import { Door } from "src/app/partner/settings/doors/door.model";
import { Reservation } from "src/app/partner/settings/reservations/reservation.model";
import { Vendor } from "src/app/partner/vendors/vendor.model";
import { getEnumMember } from "src/utils";
import { AppointmentReservationSlot } from "./appointment-reservation-slot.model";
import { AppointmentSlotDock } from "./appointment-slot-dock.model";
import { AppointmentReservationSlotRequest } from "./appointment-slot-request.model";
import { AppointmentSlot } from "./appointment-slot.model";

export class AppointmentSlotInformation {
  private constructor(args: ClassProperties<AppointmentSlotInformation>) {
    this.groupedMessages = args.groupedMessages;
    this.docks = args.docks;
    this.exceededVendorLimits = args.exceededVendorLimits;
    this.hasNoDefaultDoorGroup = args.hasNoDefaultDoorGroup;
    this.isDateInSiteDeliveryWindow = args.isDateInSiteDeliveryWindow;
    this.slots = args.slots;
  }

  public readonly groupedMessages: SlotGroupedMessages;
  public readonly docks: readonly AppointmentSlotDock[];
  public readonly exceededVendorLimits: readonly VendorLimit[] | null;
  public readonly hasNoDefaultDoorGroup: boolean;
  public readonly isDateInSiteDeliveryWindow: boolean;
  public readonly slots: readonly AppointmentSlot[];

  public static deserialize(
    data: Api.AppointmentSlotResponse,
    { docks, doorGroups, doors, site }: UnreservedSlotsDeserializeArguments,
  ): AppointmentSlotInformation {
    const { groupedMessages, messages } =
      deserializeAppointmentSlotMessages(data);

    return new AppointmentSlotInformation({
      groupedMessages,
      docks: AppointmentSlotDock.deserializeList(data.docks, {
        docks,
        doorGroups,
      }),
      exceededVendorLimits:
        data.vendors.length > 0
          ? data.vendors.map((limits) => ({
              actualLoadCount: limits.loadCount,
              loadsOverLimit: limits.loadCount - limits.maxLoadCount,
              maximumLoadCount: limits.maxLoadCount,
              vendor: { id: limits.vendorID, name: limits.name },
            }))
          : null,
      hasNoDefaultDoorGroup: messages.some(
        ({ code }) =>
          code === AppointmentSlotInformationMessageCode.NoDoorGroup,
      ),
      isDateInSiteDeliveryWindow: data.isDateInSiteDeliveryWindow,
      slots: AppointmentSlot.deserializeList(data.slots, {
        doors,
        site,
      }),
    });
  }

  /**
   * Whether slots exist for the given dock.
   * @param dock - The dock to check for slots.
   */
  public hasSlots(dock: Dock): boolean {
    return this.slots.some((slot) =>
      slot.doors.some((door) => door.dock.id === dock.id),
    );
  }
}

interface UnreservedSlotsDeserializeArguments {
  readonly docks: readonly Dock[];
  readonly doorGroups: readonly DoorGroup[];
  readonly doors: readonly Door[];
  readonly site: Site;
}

interface VendorLimit {
  readonly actualLoadCount: number;
  readonly loadsOverLimit: number;
  readonly maximumLoadCount: number;
  readonly vendor: Pick<Vendor, "id" | "name">;
}

export class AppointmentReservationSlotInformation {
  private constructor(
    args: ClassProperties<AppointmentReservationSlotInformation>,
  ) {
    this.conformantSlots = args.conformantSlots;
    this.docks = args.docks;
    this.slots = args.slots;
  }

  /** Slots that conform to the rules. */
  public readonly conformantSlots: readonly AppointmentReservationSlot[];
  public readonly docks: readonly AppointmentReservationSlotDock[];
  /** All slots, even those that violate rules. */
  public readonly slots: readonly AppointmentReservationSlot[];

  public static deserialize(
    data: Api.AppointmentReservationSlotResponse,
    { docks, request, reservations }: ReservedSlotsDeserializeArguments,
  ): AppointmentReservationSlotInformation {
    const slots = AppointmentReservationSlot.deserializeList(data.slots, {
      request,
      reservations,
    });
    return new AppointmentReservationSlotInformation({
      conformantSlots: slots.filter(
        (slot) =>
          !slot.ruleViolations?.ordersManagedType &&
          (request.isDropLoadConfigurable ||
            !slot.ruleViolations?.dropLoadAvailability),
      ),
      docks: data.docks.map((dockData) => {
        const dock = docks.find(({ id }) => id === dockData.dockID);
        if (!dock) {
          throw new Error(`Could not find dock with ID "${dockData.dockID}".`);
        }
        return { dock };
      }),
      slots,
    });
  }

  /**
   * Whether a given dock has reservations that apply to the specific slot
   * request that generated these slot results.
   *
   * @param dock - The dock to check for applicable reservations.
   */
  public hasApplicableReservations(dock: Dock): boolean {
    // Based on the API logic, if the slot dock exists in the response, then
    // there are reservations that apply for that dock.
    return this.docks.some((slotDock) => slotDock.dock.id === dock.id);
  }

  /**
   * Whether slots exist for the given dock.
   * @param dock - The dock to check for slots.
   */
  public hasSlots(dock: Dock): boolean {
    return this.slots.some((slot) =>
      slot.doors.some((door) => door.dock.id === dock.id),
    );
  }
}

type SlotGroupedMessages = {
  -readonly [key in keyof typeof AppointmentSlotInformationGroupedMessages]:
    | string[]
    | null;
};

interface AppointmentReservationSlotDock {
  readonly dock: Dock;
}

interface ReservedSlotsDeserializeArguments {
  readonly docks: readonly Dock[];
  readonly request: AppointmentReservationSlotRequest;
  readonly reservations: readonly Reservation[];
}

export enum AppointmentSlotInformationMessageCode {
  DockClosedAtSelectedTime = "Closures_Dock",
  DockDateThresholdExceeded = "DOCK_DATE_THRESHOLD",
  DoorClosedAtSelectedTime = "Closures_Door",
  DoorMaximumThresholdNotMet = "Door_maximum_Settings",
  DoorMinimumThresholdNotMet = "Door_Minimum_Settings",
  EquipmentNotAvailable = "Equipment_Not_Available",
  MaxVendorCountExceeded = "Max_Vendor_Count_Exceeded",
  NoDockCapacity = "No_Capacity_For_Dock",
  NoDoorGroup = "NO_DDG",
  NoDoorsLinkedToDoorGroup = "No_Doors_For_Door_Group",
  // The message related to this code won't be displayed on the UI,
  // and it will be removed from API code eventually as discussed.
  // It's been added here to avoid console error on deserialization.
  OverlappingWithBookedReservation = "Reservation_Overlapping",
  PastDockCutOff = "PastDockCutOff",
  SiteClosedAtSelectedTime = "Closures_Site",
  SlotClosedForCarrier = "Slot_Closed_For_Carrier",
  SlotClosedForDeliveryCarrier = "Slot_Closed_For_Delivery_Carrier",
  SlotClosedForVendor = "Slot_Closed_For_Vendor",
  // Code for guided users "Vendor Same Day Appointment Not Allowed" error message
  // (it won't be displayed on the UI.)
  VendorSameDayAppointmentNotAllowed = "Vendor_Same_Day_Appointment",
}

export enum AppointmentSlotInformationGroupedMessages {
  carriersDeliveryWindowMessages = AppointmentSlotInformationMessageCode.SlotClosedForCarrier,
  deliveryCarrierDeliveryWindowMessages = AppointmentSlotInformationMessageCode.SlotClosedForDeliveryCarrier,
  dockClosedAtSelectedTimeMessages = AppointmentSlotInformationMessageCode.DockClosedAtSelectedTime,
  dockDateThresholdExceededMessages = AppointmentSlotInformationMessageCode.DockDateThresholdExceeded,
  doorClosedAtSelectedTimeMessages = AppointmentSlotInformationMessageCode.DoorClosedAtSelectedTime,
  doorMaximumThresholdNotMetMessages = AppointmentSlotInformationMessageCode.DoorMaximumThresholdNotMet,
  doorMinimumThresholdNotMetMessages = AppointmentSlotInformationMessageCode.DoorMinimumThresholdNotMet,
  equipmentNotAvailableMessages = AppointmentSlotInformationMessageCode.EquipmentNotAvailable,
  maxVendorCountExceededMessages = AppointmentSlotInformationMessageCode.MaxVendorCountExceeded,
  noDoorsLinkedToDoorGroupMessages = AppointmentSlotInformationMessageCode.NoDoorsLinkedToDoorGroup,
  noDockCapacityMessages = AppointmentSlotInformationMessageCode.NoDockCapacity,
  pastDockCutOffMessages = AppointmentSlotInformationMessageCode.PastDockCutOff,
  siteClosedAtSelectedTimeMessages = AppointmentSlotInformationMessageCode.SiteClosedAtSelectedTime,
  vendorsDeliveryWindowMessages = AppointmentSlotInformationMessageCode.SlotClosedForVendor,
}

export function deserializeAppointmentSlotMessages(
  data: Api.AppointmentSlotResponse | Api.AppointmentReservationSlot,
): {
  messages: Array<{
    code: AppointmentSlotInformationMessageCode;
    text: string;
  }>;
  groupedMessages: SlotGroupedMessages;
} {
  const messages = data.messages.map((message) => ({
    ...message,
    // Ensures that all API message codes are handled
    code: getEnumMember(AppointmentSlotInformationMessageCode, message.code),
  }));

  const groupedMessages: SlotGroupedMessages = {
    carriersDeliveryWindowMessages: null,
    deliveryCarrierDeliveryWindowMessages: null,
    dockClosedAtSelectedTimeMessages: null,
    dockDateThresholdExceededMessages: null,
    doorClosedAtSelectedTimeMessages: null,
    doorMaximumThresholdNotMetMessages: null,
    doorMinimumThresholdNotMetMessages: null,
    equipmentNotAvailableMessages: null,
    maxVendorCountExceededMessages: null,
    noDoorsLinkedToDoorGroupMessages: null,
    noDockCapacityMessages: null,
    pastDockCutOffMessages: null,
    siteClosedAtSelectedTimeMessages: null,
    vendorsDeliveryWindowMessages: null,
  };

  Object.entries(AppointmentSlotInformationGroupedMessages).forEach(
    ([key, value]) => {
      groupedMessages[key] = getTextsFromMessages(messages, value.toString());
    },
  );

  return {
    messages,
    groupedMessages,
  };
}

function getTextsFromMessages(
  messages: Array<{
    code: AppointmentSlotInformationMessageCode;
    text: string;
  }>,
  messageCode: string,
): string[] | null {
  const texts = messages
    .filter(({ code }) => code === messageCode)
    .map(({ text }) => text);
  return texts.length ? texts : null;
}
