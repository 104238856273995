import type { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { Site } from "src/app/core/sites";
import { AppointmentStatus } from "src/app/partner/appointments/appointment-status.model";
import {
  FilterODataModel,
  getApiDetailsDecorator,
  OmitMetaProperties,
} from "src/utils";
import { AppointmentChanges } from "../appointment-changes.model";
import { AppointmentOrderChanges } from "../appointment-order-changes.model";
import {
  ApiTicketAppointment,
  HelpAssistTicketAppointment,
} from "../help-assist-ticket-appointment.model";
import {
  GlobalApiTicketAppointmentDraft,
  GlobalHelpAssistTicketAppointmentDraft,
  PartnerApiTicketAppointmentDraft,
  PartnerHelpAssistTicketAppointmentDraft,
} from "../ticket-appointment-draft/help-assist-ticket-appointment-draft.model";

const api = getApiDetailsDecorator<Api.HelpAssistTicketAppointmentSchedule>();

abstract class SharedScopeHelpAssistTicketAppointmentSchedule {
  protected constructor(
    args: ClassProperties<SharedScopeHelpAssistTicketAppointmentSchedule>,
  ) {
    this.appointment = args.appointment;
    this.isAppointmentApprovalRequired = args.isAppointmentApprovalRequired;
    this.isAppointmentOwnerApprovalRequired =
      args.isAppointmentOwnerApprovalRequired;
  }

  @api({
    key: "appointmentId",
    navigationProperty: "appointment",
    uiModel: HelpAssistTicketAppointment,
  })
  public readonly appointment: HelpAssistTicketAppointment | null;
  @api({ key: "appointmentApprovalRequired" })
  public readonly isAppointmentApprovalRequired: boolean;
  @api({ key: "appointmentOwnerApprovalRequired" })
  public readonly isAppointmentOwnerApprovalRequired: boolean;

  protected static deserializeBase(
    apiModel:
      | GlobalApiTicketAppointmentSchedule
      | PartnerApiTicketAppointmentSchedule,
    { site, statuses }: DeserializeArguments,
  ): SharedScopeHelpAssistTicketAppointmentSchedule {
    return {
      appointment: apiModel.appointment
        ? HelpAssistTicketAppointment.deserialize(apiModel.appointment, {
            site,
            statuses,
          })
        : null,
      isAppointmentApprovalRequired: apiModel.appointmentApprovalRequired,
      isAppointmentOwnerApprovalRequired:
        apiModel.appointmentOwnerApprovalRequired,
    };
  }
}

export class GlobalHelpAssistTicketAppointmentSchedule extends SharedScopeHelpAssistTicketAppointmentSchedule {
  private constructor(
    args: ClassProperties<GlobalHelpAssistTicketAppointmentSchedule>,
  ) {
    super(args);
    this.ticketAppointment = args.ticketAppointment;
  }

  @api({
    key: "ticketAppointmentId",
    navigationProperty: "ticketAppointment",
    uiModel: GlobalHelpAssistTicketAppointmentDraft,
  })
  public readonly ticketAppointment: GlobalHelpAssistTicketAppointmentDraft | null;

  public static deserialize(
    apiModel: OmitMetaProperties<GlobalApiTicketAppointmentSchedule>,
    { site, statuses }: DeserializeArguments,
  ): GlobalHelpAssistTicketAppointmentSchedule {
    return new GlobalHelpAssistTicketAppointmentSchedule({
      ...this.deserializeBase(apiModel, { site, statuses }),
      ticketAppointment: apiModel.ticketAppointment
        ? GlobalHelpAssistTicketAppointmentDraft.deserialize(
            apiModel.ticketAppointment,
            { site },
          )
        : null,
    });
  }
}

export class PartnerHelpAssistTicketAppointmentSchedule extends SharedScopeHelpAssistTicketAppointmentSchedule {
  private constructor(
    args: ClassProperties<PartnerHelpAssistTicketAppointmentSchedule>,
  ) {
    super(args);
    this.appointmentChanges = args.appointmentChanges;
    this.appointmentOrderChanges = args.appointmentOrderChanges;
    this.ticketAppointment = args.ticketAppointment;
  }

  public readonly appointmentChanges: AppointmentChanges | null;
  public readonly appointmentOrderChanges: AppointmentOrderChanges | null;

  @api({
    key: "ticketAppointmentId",
    uiModel: PartnerHelpAssistTicketAppointmentDraft,
    navigationProperty: "ticketAppointment",
  })
  public readonly ticketAppointment: PartnerHelpAssistTicketAppointmentDraft | null;

  public static deserialize(
    apiModel: OmitMetaProperties<PartnerApiTicketAppointmentSchedule>,
    { site, statuses }: DeserializeArguments,
  ): PartnerHelpAssistTicketAppointmentSchedule {
    return new PartnerHelpAssistTicketAppointmentSchedule({
      ...this.deserializeBase(apiModel, { site, statuses }),
      appointmentChanges: apiModel.historicalChanges?.appointmentChanges
        ? AppointmentChanges.deserialize(
            apiModel.historicalChanges.appointmentChanges,
            { site },
          )
        : null,
      appointmentOrderChanges: apiModel.historicalChanges?.ordersChanges
        ? AppointmentOrderChanges.deserialize(
            apiModel.historicalChanges.ordersChanges,
            { site },
          )
        : null,
      ticketAppointment: apiModel.ticketAppointment
        ? PartnerHelpAssistTicketAppointmentDraft.deserialize(
            apiModel.ticketAppointment,
            { site },
          )
        : null,
    });
  }
}

export type HelpAssistTicketAppointmentSchedule =
  | GlobalHelpAssistTicketAppointmentSchedule
  | PartnerHelpAssistTicketAppointmentSchedule;

type GlobalApiTicketAppointmentSchedule = FilterODataModel<
  Api.HelpAssistTicketAppointmentSchedule,
  "appointmentApprovalRequired" | "appointmentOwnerApprovalRequired"
> & {
  appointment?: ApiTicketAppointment | null;
  ticketAppointment?: GlobalApiTicketAppointmentDraft | null;
};

type PartnerApiTicketAppointmentSchedule = FilterODataModel<
  Api.HelpAssistTicketAppointmentSchedule,
  | "appointmentApprovalRequired"
  | "appointmentOwnerApprovalRequired"
  | "historicalChanges"
> & {
  appointment?: ApiTicketAppointment | null;
  ticketAppointment?: PartnerApiTicketAppointmentDraft | null;
};

interface DeserializeArguments {
  readonly site: Site;
  readonly statuses: readonly AppointmentStatus[];
}
