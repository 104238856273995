import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { DateTime, Duration } from "luxon";
import { GlobalSite } from "src/app/carrier/global-site.model";
import { ChangesHistory } from "src/app/core/history.model";
import { Site } from "src/app/core/sites";
import {
  AppointmentActivity,
  deserializeAppointmentActivity,
} from "src/app/partner/appointments/activities/appointment-activity.model";
import { AppointmentStatus } from "src/app/partner/appointments/appointment-status.model";
import {
  AppointmentReference,
  AppointmentVendor,
  deserializeAppointmentStatus,
  deserializeAppointmentVendor,
  getAppointmentRouteUrl,
} from "src/app/partner/appointments/base-appointment.model";
import {
  Carrier,
  deserializeSimpleCarrier,
  SimpleCarrier,
} from "src/app/partner/global/carriers";
import { Vendor } from "src/app/partner/vendors/vendor.model";
import {
  ExpandedODataModel,
  FilterODataModel,
  getApiDetailsDecorator,
  parseDateTime,
  parseDuration,
} from "src/utils";
import { AppointmentApproval } from "./ticket-appointment-approval.model";

const api = getApiDetailsDecorator<ApiTicketAppointment>();

export class HelpAssistTicketAppointment implements AppointmentReference {
  public constructor(args: ClassProperties<HelpAssistTicketAppointment>) {
    this.appointmentApproval = args.appointmentApproval;
    this.carrierOfRecord = args.carrierOfRecord;
    this.confirmationNumber = args.confirmationNumber;
    this.dueDate = args.dueDate;
    this.history = args.history;
    this.id = args.id;
    this.lastActivity = args.lastActivity;
    this.loadNumber = args.loadNumber;
    this.loadType = args.loadType;
    this.scheduledArrivalTime = args.scheduledArrivalTime;
    this.scheduledDuration = args.scheduledDuration;
    this.site = args.site;
    this.status = args.status;
    this.totalCaseCount = args.totalCaseCount;
    this.totalWarehousePalletCount = args.totalWarehousePalletCount;
    this.vendor = args.vendor;
  }

  @api({ key: "appointmentApproval", uiModel: AppointmentApproval })
  public readonly appointmentApproval: AppointmentApproval | null;
  @api({ key: "carrier", uiModel: Carrier })
  public readonly carrierOfRecord: SimpleCarrier;
  @api() public readonly confirmationNumber: string;
  @api() public readonly dueDate: DateTime | null;
  @api() public readonly history: readonly ChangesHistory[];
  @api() public readonly id: AppointmentReference["id"];
  public readonly lastActivity: AppointmentActivity | null;
  @api({ key: "clientAppointmentNumber" })
  public readonly loadNumber: string | null;
  @api() public readonly loadType: string | null;
  @api({ key: "startTime" }) public readonly scheduledArrivalTime: DateTime;
  @api() public readonly scheduledDuration: Duration;
  public readonly site: Site | GlobalSite;
  @api({
    key: "appointmentStatusID",
    uiModel: AppointmentStatus,
    navigationProperty: "appointmentStatus",
  })
  public readonly status: AppointmentStatus;
  @api() public readonly totalCaseCount: number;
  @api({ key: "totalPalletCount" })
  public readonly totalWarehousePalletCount: number;
  @api({ key: "vendor", uiModel: Vendor })
  public readonly vendor: AppointmentVendor | null;

  public static deserialize(
    data: ApiTicketAppointment,
    { site, statuses }: DeserializeArguments,
  ): HelpAssistTicketAppointment {
    return new HelpAssistTicketAppointment({
      appointmentApproval: data.appointmentApproval
        ? AppointmentApproval.deserialize(data.appointmentApproval)
        : null,
      carrierOfRecord: deserializeSimpleCarrier(data.carrier),
      confirmationNumber: data.confirmationNumber,
      dueDate: parseDateTime(data.dueDate, site),
      history: ChangesHistory.deserializeList(data.history),
      id: data.id,
      lastActivity: data.lastActivity
        ? deserializeAppointmentActivity(data.lastActivity, { site })
        : null,
      loadNumber: data.clientAppointmentNumber || null,
      loadType: data.loadType || null,
      scheduledArrivalTime: parseDateTime(data.startTime, site),
      scheduledDuration: parseDuration(data.scheduledDuration, "minutes"),
      site,
      status: deserializeAppointmentStatus(data, { statuses }),
      totalCaseCount: data.totalCaseCount,
      totalWarehousePalletCount: data.totalPalletCount,
      vendor: data.vendor
        ? deserializeAppointmentVendor(data.vendor, { site })
        : null,
    });
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getAppointmentRouteUrl(this, ...childPaths);
  }
}

export type ApiTicketAppointment = ExpandedODataModel<
  FilterODataModel<
    Api.Appointment,
    | "appointmentStatusID"
    | "clientAppointmentNumber"
    | "confirmationNumber"
    | "dueDate"
    | "history"
    | "id"
    | "loadType"
    | "scheduledDuration"
    | "startTime"
    | "totalCaseCount"
    | "totalPalletCount"
  >,
  {
    vendor: "id" | "name" | "vendorNumber";
    carrier: "id" | "name";
    appointmentApproval: keyof Api.AppointmentApproval;
    lastActivity: keyof Api.LastAppointmentActivity;
  }
>;

interface DeserializeArguments {
  readonly site: Site;
  readonly statuses: readonly AppointmentStatus[];
}
