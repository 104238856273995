import { DateTime, Duration, IANAZone, Interval } from "luxon";
import {
  AppointmentInterval,
  AppointmentIntervalDurations,
  ManagedType,
  SiteAvailabilityStatus,
} from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import {
  getPartnerRouteUrl,
  Partner,
} from "src/app/partner/global/partner.model";
import { HelpAssistTicketType } from "src/app/partner/help-assist/help-assist-enums";
import {
  apiPropertyClass,
  Brand,
  createPartnerResourceUrl,
  decodeOrElseNull,
  getApiDetailsDecorator,
  getEnumMember,
  isExistent,
  ODataModel,
  OmitMetaProperties,
  parseDateTime,
  parseDuration,
  parseNumber,
  PartnerResourceModel,
  UUID,
} from "src/utils";
import {
  AppointmentEnd,
  AppointmentLabelType,
  AppointmentOrdersColumnKey,
  AppointmentStart,
  BaseSite,
  CarrierAppointmentFlowType,
  CustomAppointmentDurationCalculationProperty,
  DockCapacityType,
  FieldValidation,
  parseAppointmentPurchaseOrderOptions,
  ScheduleAppointmentDisplayFieldKey,
  scheduleAppointmentDisplayFieldKeysCodec,
  ScheduleDoorGrouping,
  SiteHelpAssistProperty,
  siteInformationFromStringCodec,
  UnitType,
} from "./base-site.model";

export const sitesResource = createPartnerResourceUrl("sites");

interface ApiSiteList extends PartnerResourceModel<typeof sitesResource> {}

export interface ApiSite extends ODataModel<ApiSiteList> {}

const api = getApiDetailsDecorator<ApiSite>();

interface DeserializeArguments {
  partner: Partner;
}

export class Site extends BaseSite implements SiteReference {
  private constructor(
    args: Omit<
      ClassProperties<Site>,
      | "appointmentOrdersDetailsColumns"
      | "appointmentOrdersModifyColumns"
      | "defaultUnloaderDescription"
      | "isAdvancedShipNoticeDisplayed"
      | "name"
      | "requiredUnitType"
    >,
  ) {
    super(args);

    this.createdDate = args.createdDate;
    this.id = args.id;
    this.modifiedDate = args.modifiedDate;
    this.partner = args.partner;

    // Computed properties

    this.appointmentOrdersDetailsColumns =
      args.customAppointmentOrdersDetailsColumns ??
      Site.defaultAppointmentOrdersColumnKeys;
    this.appointmentOrdersModifyColumns =
      args.customAppointmentOrdersModifyColumns ??
      Site.defaultAppointmentOrdersColumnKeys;

    this.defaultUnloaderDescription = getDefaultUnloaderDescriptionDelta(
      this.supportEmail,
    );

    this.name = args.customName ? args.customName : args.defaultName;

    // Set required unit type to "Cases" or "Pallets" for purchase order form
    // validation based on the selected site unit type. See #25536 for details.
    this.requiredUnitType =
      this.appointmentOrdersModifyColumns.includes("unitCounts") &&
      this.unitCountsValidation === FieldValidation.Required
        ? this.unitType
        : null;
  }

  public static readonly defaultAppointmentOrdersColumnKeys: readonly AppointmentOrdersColumnKey[] =
    ["number", "vendor", "vendorNumber", "dueDate"];
  public static readonly defaultLateArrivalDuration = Duration.fromObject({
    minutes: 30,
  });
  public static readonly defaultScheduleThresholdDuration = Duration.fromObject(
    {
      days: 5,
    },
  );
  public static readonly defaultLateDueDateMessage =
    "The appointment date you selected is after the due date. Do you want to proceed?";
  public static readonly defaultScheduleAppointmentHoverDisplayFieldKeys: readonly ScheduleAppointmentDisplayFieldKey[] =
    [
      "vendor",
      "carrierOfRecord",
      "slotWasReserved",
      "effectiveDeliveryCarrierName",
      "mainPurchaseOrder",
      "totalWarehousePalletCount",
      "totalCaseCount",
      "managedType",
      "trailer",
    ];
  public static readonly defaultScheduleAppointmentSelectionDisplayFieldKeys: readonly ScheduleAppointmentDisplayFieldKey[] =
    [
      "scheduledArrivalTime",
      "onComplexTimestamp",
      "gateInTimestamp",
      "unloadStartTimestamp",
      "unloadEndTimestamp",
      "gateOutTimestamp",
      "offComplexTimestamp",
      "vendor",
      "carrierOfRecord",
      "slotWasReserved",
      "effectiveDeliveryCarrierName",
      "mainPurchaseOrder",
      "managedType",
      "scheduledDuration",
      "unloader",
      "trailer",
      "totalWarehousePalletCount",
      "totalCaseCount",
    ];

  public readonly appointmentOrdersDetailsColumns: readonly AppointmentOrdersColumnKey[];
  public readonly appointmentOrdersModifyColumns: readonly AppointmentOrdersColumnKey[];
  public readonly createdDate: DateTime;
  public readonly defaultUnloaderDescription: string;
  public readonly id: SiteReference["id"];
  public readonly modifiedDate: DateTime;
  public readonly name: string;
  public readonly partner: Partner;
  public readonly requiredUnitType: UnitType | null;

  public static deserialize(
    data: OmitMetaProperties<ApiSite>,
    { partner }: DeserializeArguments,
  ): Site {
    const appointmentInterval =
      // Get the same object so that the radio input comparison in the site
      // settings form matches properly for showing which is checked.
      AppointmentIntervalDurations[
        getEnumMember(
          AppointmentInterval,
          data.appointmentInterval,
          AppointmentInterval.Invalid,
        )
      ] ?? parseDuration(data.appointmentInterval, "minutes");

    return new Site({
      address: data.address ?? null,
      appointmentAutoCancelLimit: isExistent(data.apptCancelLimit)
        ? parseDuration(data.apptCancelLimit, "hours")
        : null,
      appointmentInterval,
      appointmentLabelType: getEnumMember(
        AppointmentLabelType,
        data.scheduleLabelApptField,
        // Business hasn't defined a default, so this one is arbitrary.
        AppointmentLabelType.AppointmentNumber,
      ),
      appointmentMessaging: data.appointmentMessaging || null,
      appointmentTimerStart: getEnumMember(
        AppointmentStart,
        data.appointmentStartOptions,
      ),
      areAppointmentOrdersAtDifferentDocksAllowed: data.allowApptOrdersDiffDock,
      areBackdatedAppointmentsAllowed: data.allowBackdatedAppointments,
      areBackdatedPurchaseOrdersAllowed: data.allowBackdatedPurchaseOrders,
      areDuplicatePurchaseOrderNumbersAllowed: data.allowDuplicateOrders,
      autoAppointMaxUnloadDuration: isExistent(data.autoAppointMaxUnloadMinutes)
        ? parseDuration(data.autoAppointMaxUnloadMinutes, "minutes")
        : null,
      autoAppointMinUnloadDuration: isExistent(data.autoAppointMinUnloadMinutes)
        ? parseDuration(data.autoAppointMinUnloadMinutes, "minutes")
        : null,
      businessDayOffset: parseDuration(data.businessDayOffset, "hours"),
      canRestrictedUsersViewAppointmentTimestampsAndDoors:
        data.appointmentTimeStampsAndDoorDetailsAreViewable,
      carrierAppointmentFlow: getEnumMember(
        CarrierAppointmentFlowType,
        data.carrierAppointmentFlow,
      ),
      createdDate: parseDateTime(data.createdDate),
      customAppointmentDurationCalculation:
        new CustomAppointmentDurationCalculationProperty({
          buffer: parseDuration(data.customMPUCalcBuffer ?? 0, "minutes"),
          endPoint: data.customMPUCalcEnd
            ? getEnumMember(AppointmentEnd, data.customMPUCalcEnd)
            : null,
          isEnabled: data.useCustomMPUForApptDuration,
          startPoint: data.customMPUCalcStart
            ? getEnumMember(AppointmentStart, data.customMPUCalcStart)
            : null,
        }),
      customAppointmentOrdersDetailsColumns:
        parseAppointmentPurchaseOrderOptions(
          data.apptDetailsOrdersColumnOptions,
        ),
      customAppointmentOrdersModifyColumns:
        parseAppointmentPurchaseOrderOptions(
          data.apptModifyOrdersColumnOptions,
        ),
      customName: data.displayName || null,
      customUnloaderDescription: data.guidedFlowUnloaderDescription || null,
      defaultDoorGroupId: data.defaultDoorGroup ?? null,
      defaultMaximumUnloadDurationPerUnit: parseDuration(
        data.maxCalcMinutesPerUnit,
        "minutes",
      ),
      defaultName: data.name,
      defaultUnloadDurationPerUnit: parseDuration(
        data.minutesPerUnit,
        "minutes",
      ),
      dockCapacityType: getEnumMember(DockCapacityType, data.dockCapacityType),
      earlyArrivalDuration: parseDuration(
        data.earlyArrivalInMinutes,
        "minutes",
      ),
      earlyScheduleThresholdDuration: parseDuration(
        data.earlyScheduleThreshold,
        "days",
      ),
      gatePassMessage: data.gatePassMessage || null,
      helpAssist: new SiteHelpAssistProperty({
        areAppointmentsAutomaticallyApproved: data.automaticAppointmentApproval,
        areGeneralTicketsAllowed: data.helpAssistGeneralHelpTicketsAllowed,
        autoApproveReservationsCutoff: parseDuration(
          data.autoApproveReservationsCutoff,
          "hours",
        ),
        amendmentRequestedNotificationReceivers:
          data.helpAssistNotifyOnAmendmentRequestedReceivers || null,
        approvalRequestedNotificationReceivers:
          data.helpAssistNotifyOnApprovalRequestedReceivers || null,
        ticketApprovedNotificationReceivers:
          data.helpAssistNotifyOnTicketApprovedReceivers || null,
        ticketCreatedNotificationReceivers:
          data.helpAssistNotifyOnTicketCreatedReceivers || null,
        ticketDeclinedNotificationReceivers:
          data.helpAssistNotifyOnTicketDeclinedReceivers || null,
        ticketModifiedNotificationReceivers:
          data.helpAssistNotifyOnTicketModifiedReceivers || null,
        ticketResolutionTimeDuration: parseDuration(
          data.helpAssistTicketResolutionTime,
          "hours",
        ),
        isAmendmentRequestedNotificationEnabled:
          data.helpAssistNotifyOnAmendmentRequested,
        isApprovalRequestedNotificationEnabled:
          data.helpAssistNotifyOnApprovalRequested,
        isTicketApprovedNotificationEnabled:
          data.helpAssistNotifyOnTicketApproved,
        isTicketCreatedNotificationEnabled:
          data.helpAssistNotifyOnTicketCreated,
        isTicketDeclinedNotificationEnabled:
          data.helpAssistNotifyOnTicketDeclined,
        isTicketModifiedNotificationEnabled:
          data.helpAssistNotifyOnTicketModified,
        areSchedulingTicketsAllowed: data.helpAssistTicketsSchedulingAllowed,
      }),
      helpAssistTicketCategories: data.helpAssistTicketCategories
        ? data.helpAssistTicketCategories.map((category) => ({
            name: category.name,
            ticketType: getEnumMember(
              HelpAssistTicketType,
              category.ticketType,
            ),
          }))
        : [],
      id: data.id,
      information: data.additionalSupportInfo
        ? parseInformation(data.additionalSupportInfo)
        : null,
      isAdvancedSchedulingEnabled: data.advancedScheduling,
      isAisleNumberUsed: data.useAisleNumbers,
      isAppointmentCancellationAllowed: data.unrestrictedAppointmentActions,
      isAppointmentCarrierETAEnabled: data.carrierETA ?? null,
      isAppointmentContactInformationEnabled:
        data.captureAppointmentContactInformation,
      isAppointmentIntermodalFlagEnabled: data.captureApptIntermodal,
      isAppointmentLoadWeightEnabled: data.captureApptLoadWeight,
      isAppointmentUnloaderPartnerSelectionRestricted:
        data.restrictPartnerSelectionOnUnloader,
      isDriverCdlCaptureRequired: data.captureDriverCDL ?? null,
      isDriverNameCaptureRequired: data.captureDriverName ?? null,
      isDriverPhoneNumberCaptureRequired: data.captureDriverPhoneNumber ?? null,
      isDropLoadDisallowedForTruckLoadManagedType:
        data.noDropAllowedOnManagedTypeT,
      isGateOutDoorOccupiedAppointmentStatusEnabled: data.goDoFeature,
      isLateDueDatePromptRequired: data.captureLateDueDate,
      isLoadTypeRequired: data.captureApptLoadType,
      isManualPurchaseOrderCreationAllowed: data.allowManualOrders,
      isOffComplexAppointmentStatusEnabled: data.offComplexFeature,
      isOnComplexAppointmentStatusEnabled: data.onComplexFeature,
      isPastDueDateNotificationLimited: data.limitPastDueDateNotifications,
      isPurchaseOrderEntryDateEnabled: data.captureOrderEntryDate,
      isPurchaseOrderReuseAllowed: data.allowOrderCloning,
      isPurchaseOrderUnitCountsCaptureRestricted:
        data.restrictPOPalletandCaseCaptureGuided,
      isSameDayAppointmentFlagRequired: data.promptSameDayApptConfirmation,
      isSearchByLoadNumberEnabled: data.enableSearchPOByClientAppointmentNumber,
      isTotalWarehousePalletCountOverrideEnabled:
        data.appointmentPalletOverride,
      isTrailerNumberRequired: data.captureTrailerNumber,
      isUsingContractManagedType: data.manageTypeCFeature ?? false,
      isVendorSameDayReschedulingEnabled:
        data.allowReschedulingOfVendorSameDayAppointment,
      isYardManagementSystemEnabled: data.enableYardManagementSystem,
      lateArrivalDuration: parseDuration(data.lateArrivalInMinutes, "minutes"),
      lateDueDateMessage: data.lateDueDateMessage || null,
      lateScheduleThresholdDuration: parseDuration(
        data.lateScheduleThreshold,
        "days",
      ),
      maximumAppointmentDuration: parseDuration(
        data.maxAppointmentDuration,
        "minutes",
      ),
      maximumFutureAppointmentSchedulingLimit: parseDuration(
        data.appointmentDateLimit,
        "days",
      ),
      maximumReservationTimeSlots: data.maximumReservationTimeSlots,
      minimumAppointmentDuration: parseDuration(
        data.minimumUnloadMinutes,
        "minutes",
      ),
      modifiedDate: parseDateTime(data.modifiedDate),
      noShowNoCallDuration: parseDuration(
        data.noShowNoCallInMinutes,
        "minutes",
      ),
      number: data.number,
      partner,
      pointOfOriginValidation:
        SitePointOfOriginValidationProperty.deserialize(data),
      rescheduleMinimumAdvancedDuration: parseDuration(
        data.rescheduleMinimumAdvancedHours,
        "hours",
      ),
      scheduleAppointmentHoverDisplayFields: parseScheduleOptions(
        data.scheduleAppointmentHoverOptions,
      ),
      scheduleAppointmentSelectionDisplayFields: parseScheduleOptions(
        data.scheduleAppointmentSelectionOptions,
      ),
      scheduleDoorGrouping: getEnumMember(
        ScheduleDoorGrouping,
        data.scheduleDisplayDoorGrouping,
      ),
      status: getEnumMember(SiteAvailabilityStatus, data.status),
      supportEmail: data.supportEmail,
      timeZone: IANAZone.create(data.timeZone),
      trailerTemperatureActualValidation: getEnumMember(
        FieldValidation,
        data.captureTrailerTempActual,
      ),
      trailerTemperatureSetValidation: getEnumMember(
        FieldValidation,
        data.captureTrailerTempSet,
      ),
      unitCountsValidation: getEnumMember(
        FieldValidation,
        data.capturePoQuantities,
      ),
      unitType: getEnumMember(UnitType, data.unitType),
      weeksAveragedForUnloadTime: data.wksAvgUnloadTime,
    });
  }

  public static deserializeList(
    { value }: ApiSiteList,
    args: DeserializeArguments,
  ): readonly Site[] {
    return value.map((x) => Site.deserialize(x, args));
  }

  /**
   * Parse the URL for partner and site info embedded in it.
   *
   * @param url - The URL to parse.
   */
  public static parseRouteInfo(url: string): {
    /**
     * The partner key from the URL, if present, or `"_"` if it's the "selected
     * partner" placeholder.
     */
    readonly partnerKey: UUID | "_" | null;
    /**
     * The site ID from the URL, if present, or `"_"` if it's the "selected
     * site" placeholder.
     */
    readonly siteId: number | "_" | null;
    /** The remaining part of the URL after the site path. */
    readonly childPath: string;
  } {
    const { partnerKey, childPath: partnerChildPath } =
      Partner.parseRouteInfo(url);
    const groups: UrlMatchGroups =
      partnerChildPath.match(urlSitePartMatch)?.groups ?? {};
    return {
      partnerKey,
      siteId: groups.id === "_" ? "_" : parseNumber(groups.id),
      childPath: groups.childPath ?? partnerChildPath,
    };
  }

  /**
   * Gets whether the unscheduled same day flag for an appointment would have to
   * be updated if the appointment were to be scheduled for the provided day.
   * @param day - the day to check
   */
  public mustUpdateAppointmentUnscheduledSameDayFor(
    appointmentDay: Day,
  ): boolean {
    return (
      this.isSameDayAppointmentFlagRequired &&
      appointmentDay.isTodayIn(this.timeZone)
    );
  }

  /**
   * Gets the site's business day range details for a specific time or day.
   *
   * @param timeOrDay - The time or day that the returned business day should
   * contain. Defaults to the current moment.
   */
  public getBusinessDay(timeOrDay: DateTime | Day = DateTime.utc()): {
    readonly day: Day;
    readonly range: Interval;
  } {
    if (timeOrDay instanceof Day) {
      const startOfCalendarDay = timeOrDay.toDateTime(
        Day.startOfDay,
        this.timeZone,
      );
      const startOfBusinessDay = startOfCalendarDay.plus(
        this.businessDayOffset,
      );
      return {
        day: timeOrDay,
        range: Interval.after(startOfBusinessDay, { days: 1 }),
      };
    }

    const startOfDay = new Day(timeOrDay.setZone(this.timeZone).startOf("day"));
    const startOfNextDay = startOfDay.plus({ days: 1 });
    const startOfPreviousDay = startOfDay.minus({ days: 1 });

    // Find the business day range that includes the provided time within the
    // surrounding three calendar days. We have to check all three since,
    // because of the offsets, the time could be in the previous or next
    // business day regardless of the calendar day of the time.
    const businessDay = [startOfDay, startOfNextDay, startOfPreviousDay]
      .map((day) => this.getBusinessDay(day))
      .find(({ range }) => range.contains(timeOrDay));

    if (!businessDay) {
      throw new Error(
        "Time is somehow outside of the surrounding three business days.",
      );
    }
    return businessDay;
  }

  /**
   * Replaces the site (and partner, if necessary) portion of the provided URL
   * and returns the new URL for this site.
   *
   * @param url - The URL to replace the site path in.
   */
  public replaceRouted(url: string): string {
    return this.partner.replaceRouted(
      url.replace(urlSitePartMatch, `/sites/${this.id}$2`),
    );
  }

  public getRouteUrl(...childPaths: string[]): string {
    return getSiteRouteUrl(this, ...childPaths);
  }
}

@apiPropertyClass
class SitePointOfOriginValidationProperty
  implements Readonly<Record<ManagedType, FieldValidation>>
{
  private constructor(
    args: ClassProperties<SitePointOfOriginValidationProperty>,
  ) {
    Object.assign(this, args);
  }

  @api({ key: "captureOriginTypeB" })
  public readonly [ManagedType.Backhaul]: FieldValidation;
  @api({ key: "captureOriginTypeC" })
  public readonly [ManagedType.Contract]: FieldValidation;
  @api({ key: "captureOriginTypeL" })
  public readonly [ManagedType.Logistics]: FieldValidation;
  @api({ key: "captureOriginTypeT" })
  public readonly [ManagedType.TruckLoad]: FieldValidation;

  public static deserialize(
    data: OmitMetaProperties<ApiSite>,
  ): SitePointOfOriginValidationProperty {
    return new SitePointOfOriginValidationProperty({
      [ManagedType.Backhaul]: getEnumMember(
        FieldValidation,
        data.captureOriginTypeB,
      ),
      [ManagedType.Contract]: getEnumMember(
        FieldValidation,
        data.captureOriginTypeC,
      ),
      [ManagedType.Logistics]: getEnumMember(
        FieldValidation,
        data.captureOriginTypeL,
      ),
      [ManagedType.TruckLoad]: getEnumMember(
        FieldValidation,
        data.captureOriginTypeT,
      ),
    });
  }
}

const parseInformation = decodeOrElseNull(siteInformationFromStringCodec);

export function getDefaultUnloaderDescriptionDelta(email: string): string {
  return JSON.stringify({
    ops: [
      {
        insert:
          "For questions regarding charges for unloading services please contact the site administrator at ",
      },
      { attributes: { link: `mailto:${email}` }, insert: email },
      { insert: ".\n" },
    ],
  });
}

export interface SiteReference {
  readonly id: Brand<number, "site">;
  readonly partner: Pick<Partner, "key">;
}

export interface SiteModelReference<K extends string> {
  readonly id: Brand<number, K>;
  readonly site: SiteReference;
}

export function getSiteRouteUrl(
  site: SiteReference,
  ...childPaths: string[]
): string {
  return getPartnerRouteUrl(
    site.partner,
    "sites",
    String(site.id),
    ...childPaths,
  );
}

const urlSitePartMatch = /\/sites\/(?<id>_|[0-9]+)(?<childPath>\/.*|$)/i;
interface UrlMatchGroups {
  readonly id?: string;
  readonly childPath?: string;
}

const decodeScheduleOptions = decodeOrElseNull(
  scheduleAppointmentDisplayFieldKeysCodec,
);
function parseScheduleOptions(
  value: string | null | undefined,
): readonly ScheduleAppointmentDisplayFieldKey[] | null {
  // Ignore unrecognized options and fallback to default if the JSON is invalid.
  return value
    ? decodeScheduleOptions(value)?.filter(isExistent) ?? null
    : null;
}
