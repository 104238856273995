import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { ManagedType } from "src/app/core/constants";
import { Day } from "src/app/core/day.model";
import { PointOfOrigin } from "src/app/partner/purchase-orders/base-purchase-order.model";
import { getApiDetailsDecorator } from "src/utils";
import { DoorGroup } from "../../../../../../mock-api/src/managed-receiving-partner/types";

const api = getApiDetailsDecorator<Api.TicketAppointmentOrderUpdate>();

export abstract class BaseHelpAssistTicketAppointmentOrder {
  protected constructor(
    args: ClassProperties<BaseHelpAssistTicketAppointmentOrder>,
  ) {
    this.asnBolNumber = args.asnBolNumber;
    this.asnProNumber = args.asnProNumber;
    this.backhaulPickupConfirmationNumber =
      args.backhaulPickupConfirmationNumber;
    this.bolNumber = args.bolNumber;
    this.caseCount = args.caseCount;
    this.comments = args.comments;
    this.consigneeCode = args.consigneeCode;
    this.doorGroup = args.doorGroup;
    this.dueDate = args.dueDate;
    this.entryDate = args.entryDate;
    this.inboundPalletCount = args.inboundPalletCount;
    this.loadWeight = args.loadWeight;
    this.managedType = args.managedType;
    this.number = args.number;
    this.orderId = args.orderId;
    this.pickupDate = args.pickupDate;
    this.pointOfOrigin = args.pointOfOrigin;
    this.proNumber = args.proNumber;
    this.vendorId = args.vendorId;
    this.warehousePalletCount = args.warehousePalletCount;
  }

  @api() public readonly asnBolNumber: string | null;
  @api() public readonly asnProNumber: string | null;
  @api() public readonly backhaulPickupConfirmationNumber: string | null;
  @api() public readonly bolNumber: string | null;
  @api() public readonly caseCount: number;
  @api() public readonly comments: string | null;
  @api() public readonly consigneeCode: string | null;
  @api({
    key: "doorGroupID",
  })
  public readonly doorGroup: Pick<DoorGroup, "id" | "name"> | null;
  @api() public readonly dueDate: Day | null;
  @api() public readonly entryDate: Day | null;
  @api({ key: "estimatedReceivedPalletCount" })
  public readonly inboundPalletCount: number;
  @api() public readonly loadWeight: number | null;
  @api() public readonly managedType: ManagedType | null;
  @api() public readonly number: string;
  @api() public readonly orderId: number | null;
  @api() public readonly pickupDate: Day | null;
  @api(PointOfOrigin) public readonly pointOfOrigin: PointOfOrigin | null;
  @api() public readonly proNumber: string | null;
  @api() public readonly vendorId: number;
  @api({ key: "palletCount" }) public readonly warehousePalletCount: number;
}
