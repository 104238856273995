import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { firstValueFrom } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { UserService } from "src/app/core/auth";
import { FormComponent } from "src/app/core/form.component";
import { SitesService } from "src/app/core/sites";
import { emailValidator, isExistent } from "src/utils";
import {
  HelpAssistEventType,
  HelpAssistTicketType,
} from "../help-assist-enums";
import { HelpAssistService } from "../help-assist.service";
import { HelpAssistTicketReference } from "../models/ticket/base-help-assist-ticket.model";
import { HelpAssistTicketUpdate } from "../models/ticket/help-assist-ticket-update.model";

interface FormValues {
  emailAddress: string;
  question: string;
  orders: string | null;
  isOrderEntrySkipped: boolean;
}

@UntilDestroy()
@Component({
  selector: "mr-general-ticket-form",
  templateUrl: "./general-ticket-form.component.html",
  styleUrls: ["./general-ticket-form.component.scss"],
})
export class HelpAssistGeneralTicketFormComponent
  extends FormComponent<FormValues>
  implements OnInit
{
  public constructor(
    formBuilder: FormBuilder,
    private readonly sites: SitesService,
    private readonly helpAssist: HelpAssistService,
    user: UserService,
  ) {
    super(formBuilder, {
      emailAddress: [
        user.details.emailAddress,
        [Validators.required, emailValidator],
      ],
      isOrderEntrySkipped: [false],
      orders: [null],
      question: [null, Validators.required],
    });
  }

  @Input() public isAppointmentCreation = false;
  @Input() public orderSearchTerms?: readonly string[];
  @Output() public readonly submitSuccess =
    new EventEmitter<HelpAssistTicketReference>();
  @Output() public readonly submitError = new EventEmitter<unknown>();

  public readonly areGeneralTicketsDisabledChanges =
    this.sites.selectedChanges.pipe(
      map((site) => !site.helpAssist.areGeneralTicketsAllowed),
    );

  public ngOnInit(): void {
    this.areGeneralTicketsDisabledChanges
      .pipe(untilDestroyed(this))
      .subscribe((areGeneralTicketsDisabled) => {
        if (areGeneralTicketsDisabled) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });

    if (this.isAppointmentCreation) {
      if (this.orderSearchTerms) {
        this.setValue("orders", this.orderSearchTerms.join(", "));
      }

      this.getValueChanges("isOrderEntrySkipped")
        .pipe(
          filter(isExistent),
          withLatestFrom(this.areGeneralTicketsDisabledChanges),
          untilDestroyed(this),
        )
        .subscribe(([isOrderEntrySkipped, areGeneralTicketsDisabled]) => {
          this.reconfigure("orders", {
            isDisabled: isOrderEntrySkipped || areGeneralTicketsDisabled,
            validators:
              isOrderEntrySkipped || areGeneralTicketsDisabled
                ? null
                : [Validators.required, Validators.maxLength(8000)],
          });
        });
    }
  }

  public async submit(): Promise<void> {
    const site = await firstValueFrom(this.sites.selectedChanges);

    try {
      const successResponse = await this.runSubmit(
        ({ emailAddress, isOrderEntrySkipped, orders, question }) => {
          const request = new HelpAssistTicketUpdate({
            appointmentSchedule: null,
            base: null,
            emailAddress,
            events: [
              {
                comment: question,
                type: HelpAssistEventType.Commented,
              },
            ],
            orders: isOrderEntrySkipped ? null : orders,
            phoneNumber: null,
            site,
            type: HelpAssistTicketType.General,
          });

          return this.helpAssist.update(request);
        },
      );

      if (successResponse) {
        this.submitSuccess.emit(successResponse.value);
      }
    } catch (error) {
      this.submitError.emit(error);
    }
  }
}
