import { ManagedReceivingPartnerApi as Api } from "@capstone/mock-api";
import { ManagedType } from "src/app/core/constants";
import { getUpdatedPropertiesFilter } from "src/utils";
import { appointmentOrdersColumnKeysCodec, BaseSite } from "./base-site.model";
import { Site } from "./site.model";

export type SiteUpdateArgs = ClassProperties<SiteUpdate>;

const HelpAssistAutoApproveReservationsCutoffDefaultValue = 48;

export class SiteUpdate extends BaseSite {
  public constructor({ base, ...args }: SiteUpdateArgs) {
    super(args);
    this.base = base;

    this.filterUpdatedProperties = getUpdatedPropertiesFilter(this, base, args);
  }

  private readonly filterUpdatedProperties: FilterPartial<Api.SiteUpdate>;
  public readonly base: Site | null;

  public serialize(): Partial<Api.SiteUpdate> {
    return this.filterUpdatedProperties({
      additionalSupportInfo: this.information
        ? JSON.stringify(this.information)
        : null,
      address: this.address,
      advancedScheduling: this.isAdvancedSchedulingEnabled,
      allowApptOrdersDiffDock: this.areAppointmentOrdersAtDifferentDocksAllowed,
      allowBackdatedAppointments: this.areBackdatedAppointmentsAllowed,
      allowBackdatedPurchaseOrders: this.areBackdatedPurchaseOrdersAllowed,
      allowDuplicateOrders: this.areDuplicatePurchaseOrderNumbersAllowed,
      allowManualOrders: this.isManualPurchaseOrderCreationAllowed,
      allowOrderCloning: this.isPurchaseOrderReuseAllowed,
      allowReschedulingOfVendorSameDayAppointment:
        this.isVendorSameDayReschedulingEnabled,
      appointmentDateLimit:
        this.maximumFutureAppointmentSchedulingLimit?.as("days") ?? null,
      appointmentInterval: this.appointmentInterval.as("minutes"),
      appointmentMessaging: this.appointmentMessaging,
      appointmentPalletOverride:
        this.isTotalWarehousePalletCountOverrideEnabled,
      appointmentStartOptions: this.appointmentTimerStart,
      appointmentTimeStampsAndDoorDetailsAreViewable:
        this.canRestrictedUsersViewAppointmentTimestampsAndDoors,
      apptCancelLimit: this.appointmentAutoCancelLimit?.as("hours") ?? null,
      apptDetailsOrdersColumnOptions: this.customAppointmentOrdersDetailsColumns
        ? appointmentOrdersColumnKeysCodec.encode(
            this.customAppointmentOrdersDetailsColumns,
          )
        : null,
      apptModifyOrdersColumnOptions: this.customAppointmentOrdersModifyColumns
        ? appointmentOrdersColumnKeysCodec.encode(
            this.customAppointmentOrdersModifyColumns,
          )
        : null,
      autoAppointMaxUnloadMinutes:
        this.autoAppointMaxUnloadDuration?.as("minutes") ?? null,
      autoAppointMinUnloadMinutes:
        this.autoAppointMinUnloadDuration?.as("minutes") ?? null,
      autoApproveReservationsCutoff:
        this.helpAssist.autoApproveReservationsCutoff?.as("hours") ??
        HelpAssistAutoApproveReservationsCutoffDefaultValue,
      automaticAppointmentApproval:
        this.helpAssist.areAppointmentsAutomaticallyApproved,
      businessDayOffset: this.businessDayOffset.as("hours"),
      captureAppointmentContactInformation:
        this.isAppointmentContactInformationEnabled,
      captureApptIntermodal: this.isAppointmentIntermodalFlagEnabled,
      captureApptLoadType: this.isLoadTypeRequired,
      captureApptLoadWeight: this.isAppointmentLoadWeightEnabled,
      captureDriverCDL: this.isDriverCdlCaptureRequired,
      captureDriverName: this.isDriverNameCaptureRequired,
      captureDriverPhoneNumber: this.isDriverPhoneNumberCaptureRequired,
      captureLateDueDate: this.isLateDueDatePromptRequired,
      captureOrderEntryDate: this.isPurchaseOrderEntryDateEnabled,
      captureOriginTypeB: this.pointOfOriginValidation[ManagedType.Backhaul],
      captureOriginTypeC: this.pointOfOriginValidation[ManagedType.Contract],
      captureOriginTypeL: this.pointOfOriginValidation[ManagedType.Logistics],
      captureOriginTypeT: this.pointOfOriginValidation[ManagedType.TruckLoad],
      capturePoQuantities: this.unitCountsValidation,
      captureTrailerNumber: this.isTrailerNumberRequired,
      captureTrailerTempActual: this.trailerTemperatureActualValidation,
      captureTrailerTempSet: this.trailerTemperatureSetValidation,
      carrierAppointmentFlow: this.carrierAppointmentFlow,
      carrierETA: this.isAppointmentCarrierETAEnabled,
      customMPUCalcBuffer:
        this.customAppointmentDurationCalculation.buffer.as("minutes"),
      customMPUCalcEnd: this.customAppointmentDurationCalculation.endPoint,
      customMPUCalcStart: this.customAppointmentDurationCalculation.startPoint,
      defaultDoorGroup: this.defaultDoorGroupId,
      displayName: this.customName,
      dockCapacityType: this.dockCapacityType,
      earlyArrivalInMinutes: this.earlyArrivalDuration?.as("minutes") ?? null,
      earlyScheduleThreshold: this.earlyScheduleThresholdDuration.as("days"),
      enableSearchPOByClientAppointmentNumber: this.isSearchByLoadNumberEnabled,
      enableYardManagementSystem: this.isYardManagementSystemEnabled,
      gatePassMessage: this.gatePassMessage,
      goDoFeature: this.isGateOutDoorOccupiedAppointmentStatusEnabled,
      guidedFlowUnloaderDescription: this.customUnloaderDescription,
      helpAssistGeneralHelpTicketsAllowed:
        this.helpAssist.areGeneralTicketsAllowed,
      helpAssistNotifyOnAmendmentRequested:
        this.helpAssist.isAmendmentRequestedNotificationEnabled,
      helpAssistNotifyOnAmendmentRequestedReceivers:
        this.helpAssist.amendmentRequestedNotificationReceivers,
      helpAssistNotifyOnApprovalRequested:
        this.helpAssist.isApprovalRequestedNotificationEnabled,
      helpAssistNotifyOnApprovalRequestedReceivers:
        this.helpAssist.approvalRequestedNotificationReceivers,
      helpAssistNotifyOnTicketApproved:
        this.helpAssist.isTicketApprovedNotificationEnabled,
      helpAssistNotifyOnTicketApprovedReceivers:
        this.helpAssist.ticketApprovedNotificationReceivers,
      helpAssistNotifyOnTicketCreated:
        this.helpAssist.isTicketCreatedNotificationEnabled,
      helpAssistNotifyOnTicketCreatedReceivers:
        this.helpAssist.ticketCreatedNotificationReceivers,
      helpAssistNotifyOnTicketDeclined:
        this.helpAssist.isTicketDeclinedNotificationEnabled,
      helpAssistNotifyOnTicketDeclinedReceivers:
        this.helpAssist.ticketDeclinedNotificationReceivers,
      helpAssistNotifyOnTicketModified:
        this.helpAssist.isTicketModifiedNotificationEnabled,
      helpAssistNotifyOnTicketModifiedReceivers:
        this.helpAssist.ticketModifiedNotificationReceivers,
      helpAssistTicketCategories: this.helpAssistTicketCategories.map(
        (category) => ({
          name: category.name,
          ticketType: category.ticketType,
        }),
      ),
      helpAssistTicketResolutionTime:
        this.helpAssist.ticketResolutionTimeDuration.as("hours"),
      helpAssistTicketsSchedulingAllowed:
        this.helpAssist.areSchedulingTicketsAllowed,
      lateArrivalInMinutes: this.lateArrivalDuration.as("minutes"),
      lateDueDateMessage: this.lateDueDateMessage || null,
      lateScheduleThreshold: this.lateScheduleThresholdDuration.as("days"),
      limitPastDueDateNotifications: this.isPastDueDateNotificationLimited,
      manageTypeCFeature: this.isUsingContractManagedType,
      maxAppointmentDuration: this.maximumAppointmentDuration.as("minutes"),
      maxCalcMinutesPerUnit:
        this.defaultMaximumUnloadDurationPerUnit?.as("minutes") ?? null,
      maximumReservationTimeSlots: this.maximumReservationTimeSlots,
      minimumUnloadMinutes: this.minimumAppointmentDuration.as("minutes"),
      minutesPerUnit: this.defaultUnloadDurationPerUnit.as("minutes"),
      name: this.defaultName,
      noDropAllowedOnManagedTypeT:
        this.isDropLoadDisallowedForTruckLoadManagedType,
      noShowNoCallInMinutes: this.noShowNoCallDuration?.as("minutes") ?? null,
      number: this.number,
      offComplexFeature: this.isOffComplexAppointmentStatusEnabled,
      onComplexFeature: this.isOnComplexAppointmentStatusEnabled,
      promptSameDayApptConfirmation: this.isSameDayAppointmentFlagRequired,
      rescheduleMinimumAdvancedHours:
        this.rescheduleMinimumAdvancedDuration?.as("hours") ?? null,
      restrictPartnerSelectionOnUnloader:
        this.isAppointmentUnloaderPartnerSelectionRestricted,
      restrictPOPalletandCaseCaptureGuided:
        this.isPurchaseOrderUnitCountsCaptureRestricted,
      scheduleAppointmentHoverOptions: this
        .scheduleAppointmentHoverDisplayFields
        ? JSON.stringify(this.scheduleAppointmentHoverDisplayFields)
        : null,
      scheduleAppointmentSelectionOptions: this
        .scheduleAppointmentSelectionDisplayFields
        ? JSON.stringify(this.scheduleAppointmentSelectionDisplayFields)
        : null,
      scheduleDisplayDoorGrouping: this.scheduleDoorGrouping,
      scheduleLabelApptField: this.appointmentLabelType,
      status: this.status,
      supportEmail: this.supportEmail,
      timeZone: this.timeZone.name,
      unitType: this.unitType,
      unrestrictedAppointmentActions: this.isAppointmentCancellationAllowed,
      useAisleNumbers: this.isAisleNumberUsed,
      useCustomMPUForApptDuration:
        this.customAppointmentDurationCalculation.isEnabled,
      wksAvgUnloadTime: this.weeksAveragedForUnloadTime,
    });
  }
}
