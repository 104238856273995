<ng-container *ngIf="selectedSiteChanges | async as site">
  <h3 class="heading4">
    Appointment Details
    <span
      *ngIf="
        appointmentSchedule?.appointmentChanges?.changeCount as changeCount
      "
      class="change-count"
    >
      {{ changeCount | amount: "change" }}
    </span>
  </h3>

  <mr-details-list split="thirds" [alignTitle]="alignTitle">
    <mr-details-item
      title="Load Number:"
      *ngIf="appointmentSchedule?.appointment?.loadNumber as loadNumber"
    >
      {{ loadNumber }}
    </mr-details-item>

    <mr-details-item
      title="Current Appt:"
      *ngIf="appointmentSchedule?.appointment as appointment"
    >
      <mr-link [link]="appointment.getRouteUrl()"
        >Appointment {{ appointment.confirmationNumber }}:
        {{ appointment.status.name }}
      </mr-link>
    </mr-details-item>

    <mr-details-item title="Carrier of Record:">
      <mr-appointment-details-entry
        [currentValue]="appointment.carrier?.name"
        [change]="appointmentSchedule?.appointmentChanges?.carrier"
      >
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Drop Load:">
      <mr-appointment-details-entry
        [currentValue]="appointment.isDropLoad"
        [change]="appointmentSchedule?.appointmentChanges?.isDropLoad"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value | boolean }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Unloaded by:">
      <mr-appointment-details-entry
        [currentValue]="appointment.unloader"
        [change]="appointmentSchedule?.appointmentChanges?.unloader"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ { unloader: value, site: site } | unloader }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item
      title="Intermodal:"
      *ngIf="site.isAppointmentIntermodalFlagEnabled"
    >
      <mr-appointment-details-entry
        [currentValue]="appointment.isIntermodal"
        [change]="appointmentSchedule?.appointmentChanges?.isIntermodal"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value | boolean }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item
      title="Load Weight:"
      *ngIf="site.isAppointmentLoadWeightEnabled"
    >
      <mr-appointment-details-entry
        [currentValue]="appointment.loadWeight"
        [change]="appointmentSchedule?.appointmentChanges?.totalLoadWeight"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value }} LB
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Load Type:" *ngIf="site.isLoadTypeRequired">
      <mr-appointment-details-entry
        [currentValue]="appointment.doorGroup?.name"
        [change]="appointmentSchedule?.appointmentChanges?.loadType"
      >
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Delivery Carrier:">
      <mr-appointment-details-entry
        [currentValue]="
          appointment.deliveryCarrier?.name ||
          appointment.unknownDeliveryCarrierName ||
          null
        "
        [change]="
          appointmentSchedule?.appointmentChanges?.effectiveDeliveryCarrierName
        "
      >
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Notification Email:">
      <mr-appointment-details-entry
        [currentValue]="appointment.notificationList"
        [change]="appointmentSchedule?.appointmentChanges?.notificationList"
      >
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Schedule:">
      <mr-appointment-details-entry
        [currentValue]="appointment.schedule"
        [change]="appointmentSchedule?.appointmentChanges?.schedule"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value | day }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Time:">
      <mr-appointment-details-entry
        [currentValue]="appointment.slotStartTime"
        [change]="appointmentSchedule?.appointmentChanges?.slotStartTime"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value | dateTimeInSiteZone: "time" }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item
      title="Door:"
      *mrIfHasPermission="
        Permission.ViewAppointmentDetailsPropertyTimestampsAndDoors;
        operator: 'or';
        condition: site.canRestrictedUsersViewAppointmentTimestampsAndDoors
      "
    >
      <mr-appointment-details-entry
        [currentValue]="appointment.door ? appointment.door.name : null"
        [change]="appointmentSchedule?.appointmentChanges?.slotDoor"
      >
      </mr-appointment-details-entry>
    </mr-details-item>

    <mr-details-item title="Total Units:">
      <mr-appointment-details-entry
        [currentValue]="
          appointment.totalWarehousePalletCountOverride ||
          appointment.totalWarehousePalletCount ||
          0
        "
        [change]="
          appointmentSchedule?.appointmentChanges
            ?.totalWarehousePalletCountOverride ||
          appointment.totalWarehousePalletCount
        "
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">{{
          value | amount: "Pallet"
        }}</ng-template>
      </mr-appointment-details-entry>
      /
      <mr-appointment-details-entry
        [currentValue]="appointment.totalCaseCount || 0"
        [change]="appointmentSchedule?.appointmentChanges?.totalCaseCount"
      >
        <ng-template mrTicketAppointmentDetailsEntry let-value="value">
          {{ value | amount: "Case" }}
        </ng-template>
      </mr-appointment-details-entry>
    </mr-details-item>
  </mr-details-list>
</ng-container>
